/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { toast } from 'react-toastify';
import { dateToStringFormatted } from '../../utils/dateConversion';
import { requestFields } from '../../utils/searchFields';
import { capitalizeString } from '../../utils/stringFormat';
import { generatePdfDocument } from '../../utils/generatePdf';

import { useCallFilterList } from '../../hooks/useFilterList';
import { useAuth } from '../../hooks/useAuth';
import { useSocket } from '../../hooks/useSocket';

import { Table } from '../../components/Table/index.js';
import { Title } from '../../components/Title';
import { Input } from '../../components/InputDown';
import { Select } from '../../components/Select';
import { StatusButton } from '../../components/StatusButton';
import { CriticidadeCell } from '../../components/CriticidadeCell';
import { Pagination } from '../../components/Pagination';
import { UncontrolledSearchBar } from '../../components/UncontrolledSearchBar';
import { Button } from '../../components/Button';

import { CardsContainer, Container } from './styles';
import useWindowDimensions from '../../hooks/useWindowsDimensions';
import { CallCard } from '../../components/CallCard';
import useAxios from '../../hooks/useAxios';

export interface Atendimento {
  cdAtendimento: number; //
  nomeNivelCriticidade: string; //
  dataAbertura: Date; //
  cdStatusAtendimento: string; //
  cdNivelCriticidade: string;
  cnpjCliente: Number; //
  razaoSocialCliente: string;
  nomeAtendente: string;
  nomeGrupo: string;
  nomeCliente: string;
  IdInterno: number;
  cdEmpresa: number;
  notaAtendimento: number | null;
  novo: boolean
}

export const Requests: React.FC = () => {
  const { novosAtendimentos, setNovosAtendimentos } = useSocket();
  const { pathname } = useLocation();
  const { associatedBusinesses } = useAuth();
  const callFilterList = useCallFilterList();
  const { width } = useWindowDimensions();

  const [pageCount, setPageCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [filters, setFilters] = useState<{[key: string]: string}>({
    cdEmpresaAtendente: associatedBusinesses?.length >= 2
      ? null : associatedBusinesses[0].cdEmpresa,

  } as any);

  const callNurseApi = useAxios();

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'cdAtendimento',
        Filter: Input,
        filter: 'text',
        width: 50,
        Cell: ({ cell: { value } }: any) => (
          value || 'Indisponível'
        ),
      },
      {
        Header: 'Criticidade',
        accessor: 'cdNivelCriticidade',
        value: 'cdNivelCriticidade',
        Filter: Select,
        filter: 'text',
        width: 50,
        Cell: ({ cell: { value } }: any) => (
          <CriticidadeCell nivelCriticidade={value} />
        ),
      },
      {
        Header: 'Abertura',
        accessor: 'dataFormatada',
        Filter: Input,
        filter: 'date',
        width: 50,
        Cell: ({ cell: { value } }: any) => value,
      },
      {
        Header: 'Status',
        accessor: 'statusAtendimento',
        Filter: Select,
        filter: 'text',
        value: 'cdStatusAtendimento',
        width: 50,
        Cell: ({ cell: { value } }: any) => (
          value ? capitalizeString(value.replace(/_/g, ' ')) : 'Indisponível'
        ),
      },
      {
        Header: 'CNPJ',
        accessor: 'cnpjCliente',
        Filter: Input,
        filter: 'text',
        width: 50,
        Cell: ({ cell: { value } }: any) => (
          <span>{value || 'Indisponível'}</span>
        ),
      },
      {
        Header: 'Solicitante',
        accessor: 'nomeCliente',
        Filter: Input,
        filter: 'text',
        width: 50,
        Cell: ({ cell: { value } }: any) => (
          <span>{value || 'Indisponível'}</span>
        ),
      },
      {
        Header: 'Grupo',
        accessor: 'nomeGrupo',
        Filter: Select,
        filter: 'text',
        width: 50,
        Cell: ({ cell: { value } }: any) => (
          value ? capitalizeString(value.replace(/_/g, ' ')) : 'Indisponível'
        ),
      },
      {
        Header: 'Atribuido',
        accessor: 'nomeAtendente',
        width: 50,
        Cell: ({ cell: { value } }: any) => (
          <span>{value || '-'}</span>
        ),
      },
      {
        Header: 'Avaliação',
        accessor: 'notaAtendimento',
        width: 50,
        Cell: ({ cell: { value } }: any) => (
          <span>{value || 'Não avaliado'}</span>
        ),
      },
      {
        Header: 'Ação',
        accessor: 'IdInterno',
        width: 50,
        Cell: ({ cell: { value, row: { original: { cdStatusAtendimento } } } }: any) => (

          <Link to={{ pathname: `/requests-form/${value}` }}>
            <StatusButton status={cdStatusAtendimento} />
          </Link>

        ),
      },
    ],
    [],
  );

  const [data, setData] = useState<Atendimento[]>([]);

  async function fetchRequestData() {
    try {
      Object.keys(filters).forEach(key => {
        if (!filters[key]) {
          delete filters[key];
        }
      });
      const response = await callNurseApi.post('/atendimento/pageSelect', {
        ...filters,
        pageCount: itemsPerPage,
        pageNumber,
      });

      setPageCount(response.data?.contagem);

      const atendimentosFormatados = response.data.atendimentos
        .map((atendimento : Atendimento) => {
          const dataFormatada = dateToStringFormatted(new Date(atendimento.dataAbertura));

          return { ...atendimento,
            IdInterno: atendimento.cdAtendimento,
            dataFormatada,
            novo: novosAtendimentos.atendimentos.includes(atendimento.cdAtendimento) };
        }).reverse();

      setData(atendimentosFormatados);
    } catch (e) {
      console.warn(e);
      toast.error('Erro ao buscar dados');
    }
  }

  const firstRenderRef = useRef(true);
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      const timer = setTimeout(() => {
        fetchRequestData();
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [filters]);

  useEffect(() => {
    fetchRequestData();
  }, [pageNumber, itemsPerPage]);

  useEffect(() => {
    setNovosAtendimentos({ atendimentos: [], count: 0 });
  }, [data]);

  return (
    <Container>
      <Helmet>
        <title>Bunzl | Atendimentos</title>
      </Helmet>
      <Title title="Atendimentos" />
      <UncontrolledSearchBar
        searchColumns={callFilterList}
        onFiltersChange={setFilters}
      />

      <div id="buttons-container">
        <Button
          title="Imprimir"
          onClick={() => generatePdfDocument(filters)}
        >
          Imprimir
        </Button>
      </div>
      <div id="refreshButton">
        {
          (pathname === '/requests' && novosAtendimentos.count > 0) &&
          (
            <Button onClick={() => fetchRequestData()}>{novosAtendimentos.count == 1 ? `${novosAtendimentos.count} novo atendimento em espera` : `${novosAtendimentos.count} novos atendimentos em espera`}  </Button>
          )
        }
      </div>

      {
        width > 768 ? (
          <Table
            rowProps={({ original: { novo } }: any) => ({
              ...(novo ? { className: 'new' } : {}),
            })}
            fields={requestFields}
            columns={columns}
            data={data}
              // eslint-disable-next-line react/jsx-no-bind
            apiPagination
          />
        ) : (
          <CardsContainer>
            {
              [...data].reverse().map(call => (
                <CallCard key={call.IdInterno} callData={call} />
              ))
            }
          </CardsContainer>
        )
}
      <Pagination
        pageCount={pageCount}
        pageNumber={pageNumber}
        onPageNumberChange={setPageNumber}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={setItemsPerPage}
      />
    </Container>
  );
};
