import styled from 'styled-components';
import { theme } from '../../styles/theme';

interface IMessageProps {
    owner: boolean
}

export const MessageContainer = styled.div<IMessageProps>`

  background: #ffffff;
  width: 100%;
  padding: 6px 8px;
  display: flex;

    div#title {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    #box {
        display: flex;
        flex-direction: column;
    }

        div#date {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            font-family: Poppins;
            font-size: 15px;
            font-style: italic;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;

        }

       h3 {
        width: 100%;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        margin: 0 8px;

        color: #5f5f5f;
       }


    div#message {
        display: flex;
        padding: 0 8px;

        p {

         font-family: 'Poppins';
         font-style: normal;
         font-weight: 400;
         font-size: 15px;
         display: flex;
        }
    }

    #user  {
        display: flex;
        width: 40px;
        height:40px;
    }

    #image-container {
    }
    #pdf-container {
        display: flex;
        justify-items: center;
        background-color: ${theme.colors.gray400};
        border-radius: 5px;
        max-width: max-content;
        padding: 0.7rem 0.7rem;
        margin: 10px;

        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #5f5f5f;

       
    }
    #image-message {
        object-fit: contain;
        width:200px;
        height:200px;
    }


`;

export const Content = styled.div`
    flex: 1;
    width: 100%;
    overflow-y: auto;
`;
