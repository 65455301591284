import React from 'react';
import { Chat, VideoCamera } from 'phosphor-react';
import { Button } from '../Button';
import { Content } from './style';
import date from '../../assets/Group.png';
import hours from '../../assets/hora.png';
import { dateToStringFormatted, timeToString } from '../../utils/dateConversion';
import { theme } from '../../styles/theme';

interface IProps{
  dataCriacao: string;
  cdAtendimento : number;
  setCdAtendimentoModal: (cdAtendimento : number) => void;
}

function HistoryBox({ dataCriacao, cdAtendimento, setCdAtendimentoModal } : IProps) {
  return (
    <>
      <Content>
        <div id="table">
          <h3 id="text">Chamada #{cdAtendimento}</h3>
          <div id="icons">
            <Chat size={24} color={theme.colors.gray500} weight="fill" />
            <VideoCamera size={24} color={theme.colors.gray500} weight="fill" />

          </div>
        </div>
        <div id="conteudo">
          <div id="date">
            <div>
              <img src={date} id="calend" alt="" />
              <p>{dateToStringFormatted(new Date(dataCriacao))}</p>
            </div>
            <div>
              <img src={hours} id="hora" alt="" />
              <p>{timeToString(dataCriacao)}</p>
            </div>
          </div>

          <div id="botao">
            <Button onClick={() => setCdAtendimentoModal(cdAtendimento)}>Visualizar</Button>
          </div>
        </div>
      </Content>
    </>
  );
}

export default HistoryBox;
