import styled from 'styled-components';

export const Content = styled.div`
  #box {
    background: #ffffff;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0.5rem;

    #textBox {
      height: 63px;
      width: 100%;
      display: flex;
      align-items: center;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 50px;
      width: 100%;
      border: 3px solid #f1f1f1;
      padding: 0 10px;

      input[type=file] {
        visibility: hidden;
        width: 16px;
      }

      input[type=text] {
        flex: 1;
        padding: 1rem;
        border: none;
      }

      #divInput {
        display: flex;
        width: min-content;

        .button {
          display: inline-block;
          cursor: pointer;
          padding: 3px 6px;
          width: fit-content;
        }

        #image {
          position: absolute;
          z-index: -1;
        }

      }

      div#button {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
          width: min-content;

        button{
          background: none;
          border: none;
          font-size: 14px;
          height: 28px;
      }
    }
  }
}

`;
