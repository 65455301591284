import styled, { css } from 'styled-components';

interface ActionButtonProps {
  backGroundColor: string;
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: #d9d9d9;
  padding-top: 0.5rem;
  border-right: 4px solid #ffffff;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  #camera-container{
    position: relative;
    display:flex;
    flex: 1;
    flex-direction: row;

    justify-content: space-evenly;
    margin: 0.5rem 0;

    div#callBox {
      display: flex;
      justify-content: center;
    }

    div#callMe {
      display: flex;
      justify-content: center;

    }
  }

  #buttons{
     display: flex;
     flex-direction: row;
     width: 100%;
     align-items: center;
     justify-content: center;
     gap: 30px;
     margin: 8px 0px;

}

`;

export const VideoBox = styled.section`
  position: relative;
  .callBox {
    display: flex;
    position: relative;
    overflow: hidden;

    width: 28vh;

    border: 3px solid white;
    border-radius: 10px;

    aspect-ratio: 3/4;

    @media(max-width: 720px){
      width: 20vh;
    }

    video {
      transform:rotateY(180deg);
      -webkit-transform:rotateY(180deg); /* Safari and Chrome */
      -moz-transform:rotateY(180deg); /* Firefox */

      overflow: hidden;
      object-fit: cover;
    }

    #statusIndicator {
      z-index: 5;
      position: absolute;
      top: 10px;
      left: 10px;

      display: flex;
      gap: 6px;

      button{
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 6px;

        border: none;
        border-radius: 50%;

        background-color: #33333399
      }
  }

  }
`;

interface UserBoxProps {
  isThirdPerson: boolean;
}

export const UserBox = styled(VideoBox)<UserBoxProps>`
  ${
  ({ isThirdPerson }) => isThirdPerson && css`
    @media(max-width: 720px){
      z-index: 5;
      position: absolute;
      bottom: 0rem;
      right: calc(50%-14vh);

      .callBox {
        width: 12vh;
        left: 0px;
        div#callBox {
          width: 15vh;
        }
      }
    }
  `
}
`;

export const ActionButton = styled.button<ActionButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 38px;
  width: 38px;

  border: none;
  border-radius: 50%;

  background: ${({ backGroundColor }) => backGroundColor};
`;
