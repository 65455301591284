import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const Content = styled.div`
    background: #f1f1f1;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid ${theme.colors.gray400};

  div#botao {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    button {
      font-size: 12px;
      width: fit-content;
      height: 30px;
      margin: 0;
    }
  }
  div#table {
    display: flex;
    flex-direction: row;
    align-items: center;


    #icons {
      width: min-content;
      display: flex;
      gap: 10%;
    }

    }


  #text {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;

    color: #5f5f5f;
  }

    div#conteudo {
        background: #ffffff;
        display: flex;
        align-items: end;
        padding: 8px;


}

  div#date {
      margin: 8px;
      display: flex;
      width: 100%;
      justify-content: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;

      color: #818181;

      gap: 2rem;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }

  #date {
    height: 16px;
  }
`;
