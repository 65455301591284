/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { CaretDown, CaretUp } from 'phosphor-react';
import { Container } from './styles';
import { UncontrolledInput } from '../InputDown';
import { searchOptions } from '../../utils/selectOptions';
import { Select } from '../Select';
import { ICallFilterList } from '../../hooks/useFilterList';

interface SearchBarProps {
  onFiltersChange: React.Dispatch<React.SetStateAction<{[key: string]: string}| null>>;
  searchColumns: ICallFilterList[];
}

export function UncontrolledSearchBar({
  searchColumns,
  onFiltersChange,
}: SearchBarProps) {
  const formRef = useRef<FormHandles>(null);
  const [showFields, setShowFields] = useState(true);

  function handleShowFields() {
    setShowFields(!showFields);
  }

  return (
    <Container>
      <div id="title">
        <h3>Pesquisar</h3>
        <button
          type="button"
          onClick={handleShowFields}
        >
          {
            showFields ?
              <CaretUp size={16} color="#fff" weight="fill" /> :
              <CaretDown size={16} color="#fff" weight="fill" />
          }
        </button>
      </div>

      { showFields && (
        <div id="input">
          <Form
            id="push-form"
            ref={formRef}
            onSubmit={() => 'oi'}
          >
            {searchColumns?.map((column) =>
              (
                column.inputType === 'select' ? (
                  <Select
                    options={[{ label: column.placeholder, value: column.defaultOption || '' }, ...searchOptions[column.name]]}
                    defaultValue={{ label: column.placeholder, value: column.defaultOption || '' }}
                    label=""
                    placeholder={column.placeholder}
                    name={column.name}
                    onChange={({ value }) => onFiltersChange((oldFilters) =>
                      ({ ...oldFilters, [column.name]: value }))}
                  />
                ) : (
                  <div key={column.name}>
                    <UncontrolledInput
                      name={column.name}
                      placeholder={column.placeholder}
                      label={column.name}
                      id={column.name}
                      type={column.valueType}
                      column={column}
                      onChange={(event) => {
                        onFiltersChange((oldFilters) =>
                          ({ ...oldFilters, [column.name]: event.target.value }));
                      }}
                    />
                  </div>
                )
              ))}

          </Form>
        </div>
      )}
    </Container>
  );
}
