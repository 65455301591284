/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import { keySessionStorage } from '../configuration/Constants';
import { IPermissionKeys } from '../DTO/Response/SelectUser';

import { Login } from '../pages/Login';
import { ForgotPassword } from '../pages/ForgotPassword';
import { Dashboard } from '../pages/Dashboard';
import { Requests } from '../pages/Requests';
import { RequestForm } from '../pages/RequestForm';
import { NewPassword } from '../pages/NewPassword';
import { UserPanel } from '../pages/UserPanel';
import { UserForm } from '../pages/UserForm';
import { CreateUserForm } from '../pages/CreateUserForm';
import { Clients } from '../pages/Clients';
import { Faqs } from '../pages/FAQ';
import { CreateFaq } from '../pages/CreateFaq';
import { ClientForm } from '../pages/ClientForm';

import { EditFaq } from '../components/EditFaq';
import { Layout } from './Layout';

export const Routing: React.FC = () => {
  const { permissions } = useAuth();

  function checkPermission(idPermission: IPermissionKeys, type: 'edit' | 'view') {
    const foundPermission = permissions.find(item => item.idPermissao === idPermission);

    const hasPermission = type === 'view' ? foundPermission?.visualizar : foundPermission?.editar;

    return hasPermission || null;
  }

  return (
    <Routes>
      <Route>
        <Route path="login" element={<Login />} />
        <Route path="/forgot-password" element={<NewPassword />} />
        <Route path="/forgot-password/:token" element={<NewPassword />} />
        <Route path="/new-password" element={<ForgotPassword />} />
        <Route
          path="/*"
          element={!sessionStorage.getItem(keySessionStorage.KEY_ID) ? <Navigate to="/login" /> : <PermittedRoutes />}
        />
      </Route>
    </Routes>
  );

  function PermittedRoutes() {
    const routes = [
      // ...(checkPermission('dashboard') && [<Route path="/" element={<Dashboard />} />]),
      checkPermission('dashboard', 'view') && <Route path="/" element={<Dashboard />} />,
      checkPermission('atendimento', 'view') && <Route path="/requests" element={<Requests />} />,
      checkPermission('atendimento', 'view') && <Route path="/requests-form/:id" element={<RequestForm />} />,
      checkPermission('usuario', 'view') && <Route path="/users" element={<UserPanel />} />,
      checkPermission('usuario', 'edit') && <Route path="/user-form/:id/*" element={<UserForm />} />,
      checkPermission('usuario', 'edit') && <Route path="/create-user" element={<CreateUserForm />} />,
      checkPermission('empresa', 'view') && <Route path="/clients" element={<Clients />} />,
      checkPermission('empresa', 'view') && <Route path="/client-form/:id/*" element={<ClientForm />} />,
      checkPermission('faq', 'view') && <Route path="/faqs" element={<Faqs />} />,
      checkPermission('faq', 'edit') && <Route path="/create-faq" element={<CreateFaq />} />,
      checkPermission('faq', 'edit') && <Route path="/edit-faq/:id/*" element={<EditFaq />} />,
    ];

    return (

      <Layout>
        <Routes>
          {routes.map(item => item)}
        </Routes>

      </Layout>

    );
  }
};
