import styled from 'styled-components';
import { theme } from '../../styles/theme';

interface ListItemProps {
  stripe: boolean;
}

export const Container = styled.div`
  position: absolute;
  right: 225px;
  animation: fadein 0.7s normal;

  @keyframes fadein{
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  font-family: Poppins;
  min-width: 260px;
  border-bottom: 1px solid #f1f1f1;

  #title {
    min-width: 260px;
    padding: 4px;
    background-color: #f1f1f1;
    color: #5F5F5F;

    span {
      font-weight: bold;
    }

  }

  #content {
    min-width: 260px;
    max-height: 120px;

  }
`;

export const ListItem = styled.span<ListItemProps>`
  font-family: Poppins;
  text-align: left;
  display: block;
  color: #5f5f5f;
  min-width: 260px;
  padding: 2px 4px;

  font-weight: 500;

  background-color: ${({ stripe }) => (stripe ? '#f1f1f1' : '#fff')};

  &:hover {
    background-color: #b5f269;
    color: white;
  }


`;
