/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { CaretDown, CaretUp, TagChevron } from 'phosphor-react';
import React, { LiHTMLAttributes, useState } from 'react';
import { theme } from '../../styles/theme';
import { Container } from './styles';

interface SelectProps extends LiHTMLAttributes<HTMLUListElement>{
  options?: any[];
  label?: string;
  name?: string;
  selected?: any;
  onSelect?: any;
  defaultMessage?: string;
}

export function NewSelect({
  options,
  label,
  name,
  selected,
  onSelect,
  defaultMessage,
}: SelectProps) {
  const [showOptions, setShowOptions] = useState(false);

  function handleShowOptions() {
    setShowOptions(!showOptions);
  }

  function handleChooseOption(selectValue) {
    onSelect(selectValue);
    setShowOptions(false);
  }

  return (
    <Container isActive={showOptions}>

      {label && <label htmlFor={name}>{label}</label>}
      <ul>
        <button id="select-header" type="button" onClick={handleShowOptions}>
          <span>{options.find(item => item.value === selected)?.message || defaultMessage}</span>
          {
            showOptions ?
              <CaretUp color={theme.colors.gray500} weight="bold" />
              : <CaretDown color={theme.colors.gray500} weight="bold" />
          }
        </button>
        {showOptions &&
          <div id="hide-border" />}
        <div>
          {showOptions && options.map(option => (
            <li
              onClick={() => handleChooseOption(option.value)}
              onKeyDown={() => {}}
            >
              <button type="button">
                {option.message}
              </button>
            </li>
          ))}

        </div>
      </ul>

    </Container>
  );
}
