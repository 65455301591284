import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100vh;
`;

interface ContentProps {
  showMenu: boolean;
  hidePadding: boolean;
}

export const Content = styled.div<ContentProps>`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: top;

  width: 100%;

  #separator{
    padding: 0 1rem;
    width: 99.9%;
    ${({ hidePadding }) => hidePadding && css`
      padding: 0;
    `}
  }
  ${({ showMenu }) => !showMenu && css`
    display: flex;
`}
  
`;

export const MenuButton = styled.div`
  z-index: 15;
  top: 3.2rem;
  position: fixed;

  button {
    background-color: white;
    padding: 5px;
    border: none;
    border-radius: 50%;

  }
`;
