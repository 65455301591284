/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Sticker } from 'phosphor-react';
import { Title } from '../../components/Title';
import { ContainerIcons, Content } from './styles';
import HistoryBox from '../../components/HistoryBox';
import { ChatMessages } from '../../components/ChatMessages';
import ChatInput from '../../components/ChatInput';
import Video from '../../components/Video';
import { ModalHistoric } from '../../components/historicModal';
import { ConfirmarChamada } from '../../components/AtendimentoModal';
import { useSocket } from '../../hooks/useSocket';
import { ChatHeader } from './ChatHeader';
import EndCallModal from '../../components/EndCallModal';
import { useAuth } from '../../hooks/useAuth';
import { useMedia } from '../../hooks/useMedia';
import useWindowDimensions from '../../hooks/useWindowsDimensions';
import useAxios from '../../hooks/useAxios';

export interface Data {
  cdAtendimento: number;
  cdAtendente: number;
  nomeNivelCriticidade: string; //
  dataAbertura: string; //
  statusAtendimento: string; //
  cdStatusAtendimento: string;
  cnpjCliente: string; //
  nomeAtendente: string;
  cnpjGrupo: string;
  nomeGrupo: string;
  nomeCliente: string;
  cdCliente: number;
  inativo: boolean;
  IdInterno: number;
  razaoSocialCliente: string;
  Chat: IMensagem[];
  Historico: Data[];
  Informacoes: Data[];
  cdAtendenteAuxiliar: number;
  cdGrupo: number;
  emailCliente: string;
}

export interface IMensagem {
  cdClienteRemetente: number | null;
  cdUsuarioRemetente: number | null;
  nomeRemetente: string;
  cdMensagem: number;

  extensaoArquivo?: string;
  nomeArquivo?: string;

  conteudo: string;
  dataEnvio: Date;
  cdAtendimento: number;
}

export function RequestForm() {
  const { id } = useParams();
  const idAtendimento = Number(id);

  const {
    socket,
    connected,
    receivedMessage,
    enterChatRoom,
    atendimentoFinalizado,
    setAtendimentoFinalizado,
  } = useSocket();
  const { width } = useWindowDimensions();
  const isScreenMobile = width < 720;

  const { stopStream } = useMedia();
  const [cdAtendimentoModal, setCdAtendimentoModal] = useState<number | null>(null);
  const [okModal, setOkModal] = useState<boolean | null>(true);
  const [requestData, setRequestData] = useState<Data>({} as Data);
  const [mensagemLocal, setMensagemLocal] = useState('');
  const [endCallModal, setEndCallModal] = useState(false);
  const [file, setFile] = useState();
  const [leaveType, setLeaveType] = useState('');
  const [visibleHistoricMobile, setVisibleHistoricMobile] = useState(false);

  const [showVideo, setShowVideo] = useState(false);
  const [videoVisiblity, setVideoVisibility] = useState(false);
  const videoRef = useRef(0);
  const chatMessagesRef = useRef<HTMLDivElement>(null);
  const { cdUsuario } = useAuth();

  const callNurseApi = useAxios();

  const reversedHistory = requestData.Historico && [...requestData?.Historico];

  function handleEndCall(type: 'end call' | 'leave call') {
    setLeaveType(type);
    setEndCallModal(true);
  }

  function handleVideoBox() {
    if (!videoRef.current) {
      setShowVideo(!showVideo);
      videoRef.current = 1;
    }
    setVideoVisibility(oldVisibility => !oldVisibility);
  }

  function CloseModal() {
    setOkModal(null);
  }

  async function BuscarAtendimentoInfo(cdAtendimento: number | undefined) {
    try {
      const response = await callNurseApi.get(
        `/atendimento/?cdAtendimento=${cdAtendimento}`,
      );
      if (response?.data?.status) {
        setRequestData((old) => ({ ...old, ...response.data.atendimento }));
      }
    } catch {
      toast.error('Erro ao buscar dados');
    }
  }

  async function FinalizarChamada(cdAtendimento: string | undefined, inativo: boolean) {
    try {
      if (requestData?.cdStatusAtendimento === 'F') {
        toast.error(
          'Erro ao finalizar atendimento: Atendimento já finalizado!',
        );
      } else {
        const response = await callNurseApi.put('/atendimento/end', {
          cdAtendimento,
          cdUsuario,
          cdCliente: null,
          inativo,
        });

        if (response?.data.status) {
          toast.success('Atendimento finalizado com sucesso!');
          stopStream();
          BuscarAtendimentoInfo(idAtendimento);
        } else {
          toast.error('Erro ao finalizar atendimento!');
        }
      }
    } catch {
      toast.error('Erro ao buscar dados');
    }
  }

  async function leaveCall() {
    try {
      const response = await callNurseApi.put('atendimento/removeAttendant', {
        cdUsuarioAuxiliar: requestData.cdAtendenteAuxiliar || cdUsuario,
        cdAtendimento: requestData.cdAtendimento,
      });
      if (response.data.status) {
        toast.success('Você saiu do atendimento com sucesso.');
        BuscarAtendimentoInfo(requestData.cdAtendimento);
        stopStream();
      }
    } catch (e) {
      toast.error('Erro ao sair do atendimento!');
    }
  }

  async function BuscarMensagensChat(cdAtendimento: number | undefined) {
    try {
      const response = await callNurseApi.post('/mensagem/select', {
        cdAtendimento,
        pageCount: 500,
        pageNumber: 1,
      });
      if (response.data.status) {
        setRequestData((oldRequestData: Data) => ({
          ...oldRequestData,
          Chat: response.data.messages,
        }));
      }
    } catch {
      toast.error('Erro ao buscar dados');
    }
  }

  async function BuscarHistorico(cdCliente: number) {
    try {
      const response = await callNurseApi.post('/atendimento/pageSelect', {
        cdEmpresaCliente: cdCliente,
        pageCount: 500,
        pageNumber: 1,
      });
      setRequestData((oldRequestData) => {
        const newHistory = [
          ...(oldRequestData.Historico || []),
          ...(response?.data?.atendimentos || []),
        ];
        return { ...oldRequestData, Historico: newHistory };
      });
    } catch {
      toast.error('Erro ao buscar dados');
    }
  }

  async function envioMensagem(
    cdAtendimento: number | undefined,
    dataEnvio: Date,
    conteudo: string,
  ) {
    try {
      const formData = new FormData();
      if (file) formData.append('file', file);
      formData.append('usuarioRemetente', `${cdUsuario}`);
      formData.append('dataEnvio', `${dataEnvio}`);
      formData.append('cdAtendimento', `${cdAtendimento}`);
      formData.append('conteudo', `${conteudo}`);

      const response = await callNurseApi.post(
        '/mensagem/sendFile',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      console.log('asdasda', response);

      if (response?.data.status) {
        setMensagemLocal('');
        setFile(undefined);
      } else {
        toast.error('Não foi possível enviar a mensagem');
      }
    } catch {
      toast.error('Mensagem não enviada');
    } finally {
      setTimeout(() => chatMessagesRef.current.scroll({ top: chatMessagesRef.current.scrollHeight, behavior: 'smooth' }), 100);
    }
  }
  function handleSubmit() {
    envioMensagem(requestData.cdAtendimento, new Date(), mensagemLocal);
  }

  useEffect(() => {
    const carregarInformacoes = async () => {
      await BuscarAtendimentoInfo(idAtendimento);
    };

    carregarInformacoes();

    BuscarMensagensChat(idAtendimento);
  }, []);

  useEffect(() => {
    if (requestData.cdCliente) {
      BuscarHistorico(requestData.cdCliente);
    }

    chatMessagesRef.current.scroll({ top: chatMessagesRef.current.scrollHeight });
  }, [requestData.cdCliente]);

  useEffect(() => {
    if (requestData?.cdStatusAtendimento === 'A'
    && (requestData?.cdAtendente === cdUsuario!
    || requestData?.cdAtendenteAuxiliar === cdUsuario)) {
      enterChatRoom({
        cdAtendimento: requestData.cdAtendimento,
        cdUsuario: cdUsuario!,
      });

      socket!.emit('get current signals', { cdAtendimento: requestData?.cdAtendimento });
    }
  }, [requestData]);

  useEffect(() => {
    if (receivedMessage) {
      setRequestData((oldRequestData) => {
        const newChat = [...(oldRequestData.Chat || []), receivedMessage];
        return { ...oldRequestData, Chat: newChat };
      });
      setTimeout(() => chatMessagesRef.current.scroll({ top: chatMessagesRef.current.scrollHeight, behavior: 'smooth' }), 100);
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (atendimentoFinalizado) {
      toast.info('Atendimento finalizado pelo cliente');
      BuscarAtendimentoInfo(idAtendimento);
      setAtendimentoFinalizado(false);
      setEndCallModal(false);
    }
  }, [atendimentoFinalizado]);

  useEffect(() => {
    if (connected) {
      enterChatRoom({ cdAtendimento: idAtendimento, cdUsuario: cdUsuario! });
    }
  }, [connected]);

  useEffect(() => {
    if (file) {
      console.log(typeof file, 'asdasdsad');
    }
  }, [file]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {requestData?.cdStatusAtendimento === 'P' && (
      <ConfirmarChamada
        BuscarAtendimentoInfo={BuscarAtendimentoInfo}
        cdAtendimento={idAtendimento}
        okModal={okModal}
        CloseModal={CloseModal}
        requestData={requestData}
      />
      )}

      {!isScreenMobile && <Title title="Atendimento" backButton />}
      <Content>
        <div id="chatBox">
          <div id="chatContent">
            <div id="tableChat">
              <div style={isScreenMobile && visibleHistoricMobile ? { position: 'fixed', zIndex: 1 } : {}}>
                <ChatHeader
                  requestData={requestData}
                  onVideoBoxChange={handleVideoBox}
                  onEndCall={handleEndCall}
                  setVisibleHistoricMobile={setVisibleHistoricMobile}
                  visibleHistoricMobile={visibleHistoricMobile}
                />
              </div>
              <div style={!videoVisiblity ? { display: 'hidden', maxHeight: 0, opacity: 0 } : { }}>
                {showVideo && requestData?.cdStatusAtendimento === 'A' && (
                  <Video
                    requestData={requestData}
                  />
                )}
              </div>
              <EndCallModal
                type={leaveType}
                setEndCallModal={setEndCallModal}
                endCallModal={endCallModal}
                onEndCall={(inativo: boolean) => FinalizarChamada(id, inativo)}
                onLeaveCall={leaveCall}
              />
              <ModalHistoric
                cdAtendimentoModal={cdAtendimentoModal}
                setCdAtendimentoModal={setCdAtendimentoModal}
              />
              <ChatMessages ref={chatMessagesRef} chat={requestData?.Chat || []} />
              {requestData?.cdStatusAtendimento === 'A' && (
                <ChatInput
                  mensagem={mensagemLocal}
                  setMensagemLocal={setMensagemLocal}
                  envioMensagem={() => handleSubmit()}
                  canSend={requestData?.cdAtendente === cdUsuario!
                  || requestData?.cdAtendenteAuxiliar === cdUsuario!}
                  setFile={setFile}
                  file={file}
                />
              )}
            </div>
            {(!isScreenMobile || visibleHistoricMobile) ? (
              <div id="tableHistorico">
                <div id="historicoHeader">
                  <h3 id="nameHistorico">Histórico</h3>
                </div>
                <div id="historyList">
                  {requestData?.Historico?.length > 0 &&
                reversedHistory.map((atendimento) => (
                  atendimento.cdAtendimento < requestData.cdAtendimento &&
                  atendimento.cdStatusAtendimento === 'F' &&
                  atendimento.cdAtendimento !== requestData.cdAtendimento &&
                  (
                  <HistoryBox
                    key={atendimento.cdAtendimento}
                    cdAtendimento={atendimento.cdAtendimento}
                    dataCriacao={atendimento.dataAbertura}
                    setCdAtendimentoModal={setCdAtendimentoModal}
                  />
                  )
                ))}
                </div>
              </div>
            ) : ''}
          </div>
        </div>
      </Content>

    </div>
  );
}
