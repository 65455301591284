import axios from 'axios';
import { callNurseApi } from '../services/api';
import { useAuth } from './useAuth';
import { variables, keySessionStorage } from '../configuration/Constants';

const useAxios = () => {
  const { accessToken, setToken, logout } = useAuth();

  const axiosInstance = axios.create({
    baseURL: variables.API_URL,
    headers: `Bearer ${accessToken}`,
  });

  callNurseApi.interceptors.request.use(
    async (config) => {
      if (config.url === '/auth') return config;
      const token = sessionStorage.getItem(keySessionStorage.KEY_TOKEN);

      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`;
      }
      if (!config.headers.common.Authorization || !token) {
        const { data } = await callNurseApi.get('/auth');
        const newAccessToken = data.token;
        sessionStorage.setItem(keySessionStorage.KEY_TOKEN, newAccessToken);
        config.headers.common.Authorization = `Bearer ${newAccessToken}`;
      }

      return config;
    },
  );

  callNurseApi.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response, config } = error;

      if (response.status === 401) {
        let token = accessToken;

        console.log('header req', config.headers);

        try {
          const { data } = await callNurseApi.get('/auth');
          let newAccessToken = data.token;
          console.log('🚀 ~ newAccessToken:', newAccessToken);

          if (newAccessToken) {
            sessionStorage.setItem(keySessionStorage.KEY_TOKEN, newAccessToken);
            // with new token retry original request
            // console.log(config);
            config.headers.Authorization = `Bearer ${newAccessToken}`;
            console.log('nova autorizacao');
            return callNurseApi(config);
          }
        } catch (e) {
          console.warn(e);
        }
        // logout();
      }
      // clear local storage and log user out
      return error;
    },
  );

  return callNurseApi;
};

export default useAxios;
