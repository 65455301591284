import React from 'react';
import { Container } from './styles';

interface ICriticidadeCellProps{
    nivelCriticidade: string
}

const niveisCriticidade : {[key : string] : string} = {
  B: 'Baixo',
  M: 'Médio',
  A: 'Alto',

};

export const CriticidadeCell =
({ nivelCriticidade } : ICriticidadeCellProps) => (
  <Container nivelCriticidade={nivelCriticidade}>
    <div id="iconContainer">
      <div id="ball" />
    </div>
    <div id="contentContainer">
      <span>{niveisCriticidade[nivelCriticidade] || '-'}</span>

    </div>
  </Container>
);
