import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { Table, DataTableCell, TableHeader, TableBody } from '@david.kucsai/react-pdf-table';
import { Atendimento } from '../../pages/Requests';
import BunzLogo from '../../assets/bunzl-saude-image.png';
import { PDFHeaderCell } from '../PdfHeaderCell';
import { useCallFilterList } from '../../hooks/useFilterList';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    alignItems: 'center',
    padding: '0 20',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '15 0 0 0 ',

  },
  fixedHeader: {
    marginBottom: 30,

  },
  logo: {
    margin: '10 0',
    padding: 10,
    width: 200,
    height: 100,
    justifyContent: 'center',
  },
  filterContainer: {
    margin: '10 20 10 0',
    fontSize: '14',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-between',

  },
  filterText: {
    fontSize: '12',
    width: '100%',
    color: '#8a8787e0',
    padding: '3 0',

  },
  countText: {
    padding: '10 10 0 0',
    fontSize: '11',
    width: '100%',
    fontWeight: 'light',
    color: '#8a8787e0',
  },
  bodyCell: {
    padding: '4 0',
    border: 'none',
    marginRight: '1',

  },
  bodyText: {
    padding: '0 5',
  },
  footer: {
    alignSelf: 'flex-end',
    margin: '15',
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '14',
  },
});

interface IPDFProps{
  atendimentos: Atendimento[];
  filters: {[key: string]: string}
}
export const PdfAtendimentos = ({ atendimentos, filters } :IPDFProps) => {
  const fitlersList = useCallFilterList();

  function getFilterName(id: string) {
    const filterObject = fitlersList.find(filter => filter.name === id);
    console.log({ filterObject });
    return filterObject.placeholder;
  }

  // const usedFilters = filtros?.filter(({ filterValue }) => filterValue);
  return (
    <Document
      title="relatorio_atendimentos"
    >
      <Page orientation="landscape" size="A4" style={styles.page}>
        <View fixed style={styles.header}>
          <Image
            style={styles.logo}
            src={BunzLogo}
          />
          <View style={styles.filterContainer}>
            {/* {
            usedFilters?.length ? (
              <>
                <Text>
                  Filtros utilizados:
                </Text>
                {usedFilters.map(({ Header: id, filterValue }) =>
                  (<Text key={id} style={styles.filterText}>{`${id}: ${filterValue}`}</Text>))}
              </>
            ) :
              <Text>Nenhum filtro utilizado</Text>

          } */}
            {
            Object.keys(filters).length ? (
              <>
                <Text>Fitltros utilizados</Text>
                {Object.entries(filters).map(([key, value]) => (
                  <Text key={key} style={styles.filterText}>
                    {`${getFilterName(key)}: ${value}`}
                  </Text>
                ))}
              </>
            ) : <Text>Nenhum filtro utilizado</Text>

          }
            <Text style={styles.countText}>{`${atendimentos.length} registro(s) encontrado(s)`}</Text>
          </View>

        </View>
        <Table evenRowColor="#e8e6e644" oddRowColor="#fff" data={atendimentos}>
          <TableHeader
            includeBottomBorder={false}
            includeTopBorder={false}
            includeLeftBorder={false}
            includeRightBorder={false}
            textAlign="left"
          >
            <PDFHeaderCell weighting={0.3} text="ID" />
            <PDFHeaderCell weighting={1} text="Empresa Cliente" />
            <PDFHeaderCell weighting={0.5} text="Nome Cliente" />
            <PDFHeaderCell weighting={0.5} text="Atendente" />
            <PDFHeaderCell weighting={0.2} text="Nota" />
          </TableHeader>
          <TableBody
            includeBottomBorder={false}
            includeLeftBorder={false}
            includeRightBorder={false}
            includeTopBorder={false}
          >
            <DataTableCell style={styles.bodyCell} textAlign="left" weighting={0.3} getContent={(r : Atendimento) => <Text style={styles.bodyText}>{r.cdAtendimento}</Text>} />
            <DataTableCell style={styles.bodyCell} textAlign="left" weighting={1} getContent={(r : Atendimento) => <Text style={styles.bodyText}>{r.razaoSocialCliente}</Text>} />
            <DataTableCell style={styles.bodyCell} textAlign="left" weighting={0.5} getContent={(r : Atendimento) => <Text style={styles.bodyText}>{r.nomeCliente}</Text>} />
            <DataTableCell style={styles.bodyCell} textAlign="left" weighting={0.5} getContent={(r : Atendimento) => <Text style={styles.bodyText}>{r.nomeAtendente}</Text>} />
            <DataTableCell style={styles.bodyCell} textAlign="left" weighting={0.2} getContent={(r : Atendimento) => <Text style={styles.bodyText}>{r.notaAtendimento || '-'}</Text>} />
          </TableBody>
        </Table>

        <View fixed>
          <Text
            style={styles.footer}
            render={({ pageNumber, totalPages }) => (
              `PÁGINA ${pageNumber} DE ${totalPages}`
            )}
            fixed
          />
        </View>
      </Page>
    </Document>
  );
};
