import styled, { css } from 'styled-components';
import { theme } from '../../styles/theme';

interface ContainerProps {
  isActive: boolean;
}

export const Container = styled.div<ContainerProps>`
    display:flex;
    flex-direction: column;
    align-items: center;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      cursor: pointer;



      button#select-header {
        display: flex;
        flex-direction: row;
        border-radius: 4rem;
        justify-content: space-between;
        background-color: ${theme.colors.background};
        padding: 1rem;
        margin-top: 0.8rem;
        border: 0.2rem solid #e3e3e3;
        z-index: 1;
        width: 33vw;
        min-width: max-content;
        ${({ isActive }) => isActive && css`
          border-bottom: 0;
        `}

        span{
          display: block;
          color: ${theme.colors.gray600}
        }
      }
      #hide-border{
        display: block;
        background-color: ${theme.colors.background};
        z-index: 2;
        height: 16px;
        border: none;
        margin-top: -14px;
        border-right: 0.2rem solid #e3e3e3;
        border-left: 0.2rem solid #e3e3e3;
        padding: 0px;
        width: 100%;
      }

      div {
        position: absolute;
        align-self: center;
        border: 0.2rem solid #e3e3e3;
        margin: 0 32px 0 32px;
        top: 5.2rem;
        max-height: 200px;
        margin-top: -28px;
        padding-top: 28px;
        width: 33vw;
        overflow-y:auto;
        background-color: ${theme.colors.background};
        ${({ isActive }) => !isActive && css`
          border: 0;
        `}

        li {
          list-style-type: none;

          button {
            width: 100%;
            padding: 10px;
            text-align: left;
            background: transparent;
            border: none;
            &:focus{
              background-color: #88ff88

            }

          }


          &:hover{
            background-color: #88ff88
          }
        }
      }

    }
`;
