/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from 'react';
import { Microphone, MicrophoneSlash, VideoCamera, VideoCameraSlash } from 'phosphor-react';

import { ActionButton, Content, UserBox, VideoBox } from './styles';
import { Data } from '../../pages/RequestForm';
import { theme } from '../../styles/theme';
import { useMedia } from '../../hooks/useMedia';

interface Props{
  requestData: Data;
}

const VideoPlayer = ({ peer, indicator }: any) => {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    peer.on('stream', (stream: any) => {
      console.log({ stream });
      ref.current!.srcObject = stream;
    });
  }, []);

  console.log({ indicator });

  return (
    <>
      <div id="statusIndicator">
        {
        indicator?.muted && (
        <button type="button">
          <MicrophoneSlash color="#fff" weight="fill" size={18} />
        </button>
        )
        }
        {
        indicator?.hidden && (
        <button type="button">
          <VideoCameraSlash color="#fff" weight="fill" size={18} />
        </button>
        )
        }
      </div>
      <video id="remoteCam" playsInline autoPlay ref={ref} />
    </>
  );
};

export default function Video({
  requestData,
}: Props) {
  const {
    localStreamRef,
    generateLocalPeer,
    peers,
    handleVideoAction,
    indicatorsRef,
  } = useMedia();
  const [audioStream, setAudioStream] = useState(true);
  const [videoStream, setVideoStream] = useState(true);

  const handleMute = () => {
    console.log('localStreamRef', localStreamRef.current!.srcObject);
    localStreamRef.current!.srcObject.getAudioTracks()[0].enabled = !audioStream;

    setAudioStream(!audioStream);
    handleVideoAction(!audioStream, videoStream);
  };

  const handleVideoStream = () => {
    localStreamRef.current!.srcObject.getVideoTracks()[0].enabled = !videoStream;

    setVideoStream(!videoStream);
    handleVideoAction(audioStream, !videoStream);
  };

  useEffect(() => {
    generateLocalPeer(requestData.cdAtendimento);
  }, []);

  return (
    <Content>
      <div id="camera-container">
        <UserBox isThirdPerson={peers.length >= 2}>
          <div className="callBox">
            <video id="remoteCam" autoPlay playsInline ref={localStreamRef} muted />
          </div>
        </UserBox>
        {peers.map((peer, index) => (
          <VideoBox>
            <div className="callBox">
              <VideoPlayer key={peer.readableLength} peer={peer} indicator={indicatorsRef.current[index]} />
            </div>
          </VideoBox>
        ))}
      </div>
      <div id="buttons">
        <ActionButton onClick={handleMute} backGroundColor={theme.colors.greenMain}>
          { audioStream
            ? <Microphone size="24" weight="fill" color="#FFF" />
            : <MicrophoneSlash size="24" weight="fill" color="#FFF" /> }
        </ActionButton>
        <ActionButton onClick={handleVideoStream} backGroundColor={theme.colors.blueMain}>
          {videoStream
            ? <VideoCamera size="24" weight="fill" color="#FFF" />
            : <VideoCameraSlash size="24" weight="fill" color="#FFF" />}
        </ActionButton>
      </div>
    </Content>
  );
}
