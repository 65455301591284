import { useMemo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

import { clientsFields } from '../../utils/searchFields';

import { useAuth } from '../../hooks/useAuth';

import { Title } from '../../components/Title';
import { Input } from '../../components/InputDown';
import { Table } from '../../components/Table/index.js';
import { ModalConfirmation } from '../../components/ModalConfimation';
import useAxios from '../../hooks/useAxios';

interface FaqData {
  IdInterno: number;
  ordem: string;
  titulo: string;
  cdFaq: Number;
  conteudo: string;
  cdUsuarioAlteracao: number | null;

}

export function Faqs() {
  const [faqs, setFaqs] = useState<FaqData[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const idUsuario = sessionStorage.getItem('id');
  const { permissions } = useAuth();
  const callNurseApi = useAxios();

  const faqPermission = permissions.find(item => item.idPermissao === 'faq');

  async function BuscarFaqs() {
    try {
      const response = await callNurseApi.post('/faq/select', { });

      const FaqsFormatados = response.data.faqs
        .map((Faq : FaqData) => ({ ...Faq, IdInterno: Faq.cdFaq }));

      setFaqs(FaqsFormatados);
    } catch {
      toast.error('Erro ao buscar dados');
    }
  }

  const deleteFaq = async (cdFaq: number) => {
    try {
      const response = await callNurseApi.delete('/faq/', { data: { cdUsuarioAlteracao: idUsuario, cdFaq } });

      if (response.data.status) {
        toast.success('FAQ excluído com sucesso!');
        BuscarFaqs();
      } else {
        toast.error(response?.data?.message || 'Não foi possível excluir o FAQ!');
      }
    } catch {
      toast.error('Erro ao buscar dados');
    }
  };

  useEffect(() => {
    BuscarFaqs();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'cdFaq', // accessor is the "key" in the data
        Filter: Input,
        filter: 'fuzzyText',
        width: 50,
      },
      {
        Header: 'Ordem',
        accessor: 'ordem', // accessor is the "key" in the data
        Filter: Input,
        filter: 'fuzzyText',
        width: 50,
        show: false,
      },
      {
        Header: 'Titulo',
        accessor: 'titulo',
        Filter: Input,
        filter: 'fuzzyText',
        Cell: ({ cell: { value } }: any) => (
          <p>{value || 'julio'}</p>
        ),
      },
      {
        Header: 'Conteúdo',
        accessor: 'conteudo',
        Filter: Input,
        filter: 'fuzzyText',
        width: 100,
      },
      {
        Header: 'Ação',
        accessor: 'IdInterno',
        width: 50,
        Cell: ({ cell: { value } }: any) => (
          <div id="action">
            {faqPermission.editar ? (
              <>
                <Link to={{ pathname: `/edit-faq/${value}` }}>
                  <button type="button">

                    Alterar
                  </button>
                </Link>
                <button onClick={() => deleteFaq(value)} className="action" type="button">
                  Excluir
                </button>
              </>
            ) : (
              <button type="button" style={{ background: '#bbb' }}>
                Nenhuma ação
              </button>
            )}
          </div>
        ),
      },

    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Bunzl | FAQ</title>
      </Helmet>
      <Title title="Gerenciamento de FAQ" addButton={faqPermission.editar ? 'create-faq' : null} />
      <Table columns={columns} data={faqs} fields={clientsFields} />
      <ModalConfirmation
        mensagem="Deseja excluir este FAQ?"
        showform={showModal}
        setShowform={setShowModal}
      />
    </>
  );
}
