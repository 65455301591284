import { useAuth } from './useAuth';

export interface ICallFilterList {
  name: string;
  inputType: 'select' | 'input';
  valueType: 'number' | 'date' | 'text' | null;
  placeholder: string;
  source?: {
    id: number | string;
    name: string;
  }[];
  defaultOption?: null | number | string;
}

export function useCallFilterList(): ICallFilterList[] {
  const { associatedBusinesses } = useAuth();
  return [
    {
      name: 'cdEmpresaAtendente',
      inputType: 'select',
      valueType: null,
      placeholder: 'Grupo',
      source: associatedBusinesses?.map(item => ({
        id: item.cdEmpresa,
        name: item.razaoSocialEmpresa,
      })),
      // eslint-disable-next-line no-nested-ternary
      defaultOption: associatedBusinesses?.length > 0 ? (associatedBusinesses?.length >= 2
        ? null : associatedBusinesses[0].cdEmpresa) : null,
    },
    {
      name: 'cdAtendimento',
      inputType: 'input',
      valueType: 'number',
      placeholder: 'Código de atendimento',
    },
    {
      name: 'dtAbertura',
      inputType: 'input',
      valueType: 'date',
      placeholder: 'Data de abertura',
    },
    {
      name: 'nomeCliente',
      inputType: 'input',
      valueType: 'text',
      placeholder: 'Solicitante',
    },
    {
      name: 'cnpjCliente',
      inputType: 'input',
      valueType: 'text',
      placeholder: 'CNPJ',
    },
    {
      name: 'cdNivelCriticidade',
      inputType: 'select',
      valueType: null,
      placeholder: 'Nível de criticidade',
    },
    {
      name: 'cdStatusAtendimento',
      inputType: 'select',
      valueType: null,
      placeholder: 'Status do atendimento',
    },
  ];
}
