import styled from 'styled-components';
import { theme } from '../../styles/theme';
import CheckSvg from '../../assets/check.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  label{
    font-family: 'Poppins';
    margin-left: 8px;
    font-weight: 500;
    color: ${theme.colors.black}
  }

`;
