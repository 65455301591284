import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IMensagem } from '../../pages/RequestForm';

import { Button } from '../Button';
import { ChatMessages } from '../ChatMessages';
import { Container, GlobalStyle } from './styles';
import useAxios from '../../hooks/useAxios';

interface Modal {
    setCdAtendimentoModal: (cdAtendimentoModal: number | null) => void,
    cdAtendimentoModal: number | null;
}

export const ModalHistoric = ({
  cdAtendimentoModal,
  setCdAtendimentoModal,

}:Modal) => {
  const [chatModal, setChatModal] = useState<IMensagem[]>([]);

  const callNurseApi = useAxios();

  async function chatView() {
    try {
      const response = await callNurseApi.post('/mensagem/select', {
        cdAtendimento: cdAtendimentoModal,
        pageCount: 500,
        pageNumber: 1,
      });
      if (response.data.status) {
        setChatModal(response.data.messages);
      }
    } catch {
      toast.error('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    if (cdAtendimentoModal != null) {
      chatView();
    }
  }, [cdAtendimentoModal]);
  return (
    <Container showModal={cdAtendimentoModal !== null}>
      <div id="ModalHistoric">
        {cdAtendimentoModal !== null && <GlobalStyle />}
        {
          (
            chatModal?.length > 0 ?
              <ChatMessages chat={chatModal} /> :
              <p id="p">Atendimento não possui mensagens</p>
            )
        }
        <div id="BoxButton">
          <Button id="button" onClick={() => setCdAtendimentoModal(null)}>
            Fechar
          </Button>
        </div>
      </div>
    </Container>
  );
};
