import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: white;
  padding: 10px;
  width: 100%;
  height: 400px;

  .charts{

  }
  .ReactChart {
      svg{
    }

  }
`;
