import styled from 'styled-components';
import { theme } from '../../styles/theme';

const coresStatus : { [key : string] : string} = {
  P: theme.colors.blueMain,
  A: theme.colors.greenMain,
  F: theme.colors.gray500,
};

export const RedirectButton = styled.button<{status: string}>`
background-color: ${({ status }) => coresStatus[status]} !important
`;
