import { useState } from 'react';
import { Usuario } from '../../pages/UserForm';

import { Button } from '../Button';
import { Input } from '../Input';

import { Content } from './styles';

interface UserInfoProps {
  nome: any;
  setName: any;
  email: any;
  setEmail: any;
  password?: string;
  setPassword?: (string: any) => void;
}

export function UserInfo({
  nome,
  setName,
  email,
  setEmail,
  password,
  setPassword,
}: UserInfoProps) {
  return (
    <>
      <h2>Editar</h2>
      <Content>
        <div id="avatar" />
        <div id="info-usuario">
          <Input
            name="nome"
            label="Nome"
            defaultValue={nome}
            onChange={(event) => setName(event.target.value)}
            required
          />
          <Input
            name="email"
            label="E-mail"
            type="email"
            required
            defaultValue={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          {
            setPassword && (
            <Input
              name="senha"
              label="Senha"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            )
}
        </div>
        <div id="button">
          <Button type="submit">Salvar</Button>
        </div>
      </Content>
    </>
  );
}
