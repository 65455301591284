/* eslint-disable react/jsx-no-bind */
import { List } from 'phosphor-react';
import { ReactNode } from 'react';
import { useLocation } from 'react-router';
import { Sidebar } from '../components/Sidebar';
import { Userbar } from '../components/Userbar';
import { useMenu } from '../hooks/useMenu';
import useWindowDimensions from '../hooks/useWindowsDimensions';

import { Container, Content, MenuButton } from '../styles/routes';
import { theme } from '../styles/theme';

interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  const { pathname } = useLocation();

  const { handleShowMenu, showMenu } = useMenu();
  const { isScreenMobile } = useWindowDimensions();

  const showUserbar = !pathname.includes('requests-form') || !isScreenMobile;
  const showMenuButton = !pathname.includes('requests-form') || showMenu;
  const hidePadding = pathname.includes('requests-form') && isScreenMobile;

  return (
    <Container>
      {(showMenuButton || !isScreenMobile) && (
        <MenuButton>
          <button type="button" onClick={handleShowMenu}>
            <List size={24} color={theme.colors.blueMain} />
          </button>
        </MenuButton>
      )}
      <Content showMenu={showMenu} hidePadding={hidePadding}>
        {
            showMenu &&
            <Sidebar showMenu={showMenu} />
          }
        <div id="separator">
          {showUserbar && <Userbar onShowMenu={handleShowMenu} />}
          {children}
        </div>
      </Content>
    </Container>
  );
}
