import { useEffect } from 'react';

import { Container } from './styles';

interface PaginationProps {
  pageCount: number;
  pageNumber: number;
  onPageNumberChange:(number) => void;
  itemsPerPage: number;
  onItemsPerPageChange: (number) => void;
}

export function Pagination({
  pageCount,
  pageNumber,
  onPageNumberChange,
  itemsPerPage,
  onItemsPerPageChange,
}: PaginationProps) {
  const totalPages = Math.ceil(pageCount / itemsPerPage);

  function goToFirstPage() {
    onPageNumberChange(1);
  }

  function goToLastPage() {
    onPageNumberChange(totalPages);
  }

  function goToNextPage() {
    onPageNumberChange(currentPage => currentPage + 1);
  }

  function goToPreviousPage() {
    onPageNumberChange(currentPage => currentPage - 1);
  }

  function goToPage(page: number) {
    onPageNumberChange(page);
  }

  useEffect(() => {
    if (pageNumber > totalPages && totalPages >= 1) {
      onPageNumberChange(totalPages);
    }
  }, [totalPages]);

  return (
    <Container>
      <button type="button" onClick={() => goToFirstPage()} disabled={pageNumber <= 1}>
        {'<<'}
      </button>{' '}
      <button type="button" onClick={() => goToPreviousPage()} disabled={pageNumber <= 1}>
        {'<'}
      </button>{' '}
      <button type="button" onClick={() => goToNextPage()} disabled={pageNumber >= totalPages}>
        {'>'}
      </button>{' '}
      <button type="button" onClick={() => goToLastPage()} disabled={pageNumber >= totalPages}>
        {'>>'}
      </button>{' '}
      <span>
        Página{' '}
        <strong>
          {pageNumber} de {totalPages}
        </strong>{' '}
      </span>
      <span>
        | Ir para:{' '}
        <input
          type="number"
          defaultValue={pageNumber}
          onChange={e => {
            const page = Number(e.target.value);
            goToPage(page);
          }}
          style={{ width: '100px' }}
        />
      </span>{' '}
      <select
        value={itemsPerPage}
        onChange={e => {
          onItemsPerPageChange(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Mostrar {pageSize}
          </option>
        ))}
      </select>
    </Container>
  );
}
