import styled from 'styled-components';

const coresNiveis : {[key : string] : string} = {
  B: 'green',
  M: 'yellow',
  A: 'red',
};

export const Container = styled.div<{nivelCriticidade: string}>`
display: flex;
align-items: center;
gap: 5%;
#ball{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ nivelCriticidade }) => coresNiveis[nivelCriticidade] || 'gray'};
}
#iconContainer{
  width: 40% ;
  display:flex;
  justify-content: right ;
  align-items: center ;
}
`;
