import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  height: 100vh;


`;

export const Content = styled.div`

  div{
    width: 100%;
  }
`;
