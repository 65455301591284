/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/require-default-props */
import { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import ReactSelect, { Props, OptionProps, ControlProps } from 'react-select';

import { theme } from '../../styles/theme';
import { ContainerWrapper, NewContainer } from './styles';

interface SelectProps extends Props {
  name: string;
  options: any;
  label?: string;
}

const customStyles = {
  control: (provided, state: ControlProps) => ({
    ...provided,
    border: '0.2rem solid #e3e3e3',
    borderColor: `${state.isFocused ? theme.colors.blueMain : '#e3e3e3'} !important`,
    boxShadow: `${state.isFocused ? theme.colors.blueMain : '#e3e3e3'} !important`,
    backgroundColor: theme.colors.background,
    outline: 'none',
    padding: '0.4rem',
    borderRadius: '4rem',
    flexWrap: 'noWrap',
    width: 'auto',
    marginTop: '0.8rem',

  }),
  dropDownIndicator: (provided, state) => ({

  }),
  option: (provided, state: OptionProps) => ({
    ...provided,
    padding: '1.2rem',
    color: theme.colors.black,
    backgroundColor: state.isSelected
      ? '#88ff88'
      : state.isFocused ? '#b4ffb4'
        : '',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  // placeholder: (provided, state) => ({
  //   ...provided,
  //   width: '100%',
  // }),
  // valueContainer: (provided, state) => ({
  //   overflow: 'initial',
  // }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    width: '40px !important',
  }),
  indicatorSeparator: (provided, state) => ({
  }),
  input: (provided, state) => ({
    ...provided,
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: theme.colors.background,
    borderRadius: '8px',
  }),
};

export function Select({ name, options, label, ...rest }: SelectProps) {
  const inputRef = useRef<HTMLSelectElement>(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <ContainerWrapper>
      {label && <label htmlFor={name}>{label}</label>}
      <NewContainer>
        <ReactSelect
          defaultInputValue=""
          options={options}
          styles={customStyles}
          {...rest}
        />
      </NewContainer>
    </ContainerWrapper>
  );
}

// export function SelectColumnFilter({
//   column: { Header, filterValue, setFilter, id } }:optionsProps) {
//   return (
//     <ContainerWrapper>
//       <Container>
//         <select
//           value={filterValue}
//           onChange={e => {
//             setFilter(e.target.value || undefined);
//           }}
//         >
//           <option value="">{Header}</option>
//           {messages[id] && messages[id].map((status:any) => (
//             <option key={`id-${status.value}`} value={status.value}>
//               {status.message}
//             </option>
//           ))}
//         </select>
//       </Container>
//     </ContainerWrapper>
//   );
// }

// export function SelectClienteNotificacaoPush({
//   column: { filterValue, setFilter, preFilteredRows, id, label },
// }:optionsProps) {
//   const {
//     adicionarCliente,
//   } = useNotificacaoContext();
//   const [clientList, setClienteList] = useState<any>();
//   useEffect(() => {
//     callNurseApi.get('/cliente/SelecionarTodos?pageNumber=0&limit=99999')
//     .then(response => setClienteList(response.data.Clientes));
//   },
//   []);

//   const options:any = React.useMemo(() => {
//     const opt = new Set();
//     preFilteredRows.forEach((row: { values: { [x: string]: any; }; }) => {
//       opt.add(row.values[id]);
//     });
//     return [...opt.values()];
//   }, [id, preFilteredRows]);

//   function handleValue(target:any) {
//     setFilter(target.value || undefined);
//     const filtroCliente = clientList.filter(
//       (item: { RazaoSocial: any; }) => item.RazaoSocial === target.value,
//     );

//     adicionarCliente(
//       { idCliente: filtroCliente[0]?.Id },
//     );
//   }
//   return (
//     <ContainerWrapper>
//       {label && <label htmlFor="opicao-cliente">Seleção de clientes</label>}
//       <Container>
//         <select
//           value={filterValue}
//           onChange={e => {
//             handleValue(e.target);
//           }}
//         >

//           <option id="opicao-cliente" value="">Selecionar Cliente</option>
//           {options.map((status:any) => (
//             <option key={status} value={status}>

//               {status}
//             </option>
//           ))}
//         </select>
//       </Container>
//     </ContainerWrapper>
//   );
// }

// export function UncontrolledSelect({ children, ...rest }: UncontrolledSelectPrps) {
//   return (
//     <ContainerWrapper>
//       <Container>
//         <select
//           {...rest}
//         >
//           {children}

//         </select>
//       </Container>

//     </ContainerWrapper>
//   );
// }
