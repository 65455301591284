import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #fff;
  padding: 1rem;

  border: 1px solid ${theme.colors.gray400};

  time {
    font-size: 1.5rem;
    font-weight: thin;
    color: ${theme.colors.gray600};
  }

  h1{
    font-size: 18px;
    font-weight: bold;
    color: ${theme.colors.blueMain};
  }

  div {
    display: flex;
    align-items: center;
    gap: 1rem;

    svg {
      color: ${theme.colors.gray600}
    }
    p{
      color: ${theme.colors.gray600};
    }
  }

  a {
    button {
      height: 3rem;
    }
    margin-top: auto;
  }
`;

const criticalLevels = {
  A: 'statusRed',
  M: 'statusYellow',
  B: 'statusGreen',

  F: 'statusGreen',
  P: 'statusYellow',
  C: 'gray500',
};

type StatusProps = {
  color: string;
}

export const Status = styled.div<StatusProps>`
  color: ${theme.colors.gray600};
  &::after{
    content: '';
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: ${({ color }) => theme.colors[criticalLevels[color]]}
  }
`;

export const Separator = styled.div`
  background-color: ${theme.colors.gray400};
  width: 100%;
  height: 1px;

`;
