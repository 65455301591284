import { StyleSheet, Text } from '@react-pdf/renderer';

import { TableCell, TableCellProps } from '@david.kucsai/react-pdf-table';

interface IHeaderCellProps extends TableCellProps {
  text: string
}

const styles = StyleSheet.create({
  headerCell: {
    backgroundColor: '#8a878728',
    padding: '8 0',
    border: 'none',
    marginRight: '1',
  },
  headerText: {
    padding: '0 5',
    fontWeight: 'bold',

  },
});

export const PDFHeaderCell = ({ text, ...rest }: IHeaderCellProps) => (
  <TableCell {...rest} style={styles.headerCell}>
    <Text style={styles.headerText}>{text}</Text>
  </TableCell>
);
