import styled, { css } from 'styled-components';
import { theme } from '../../styles/theme';

interface ButtonProps {
   refuse?: boolean;
}

export const Container = styled.div`
  position: absolute;
  background-color: white;
  position: absolute;
  bottom: 16rem;
  right: 32rem;

`;

export const Content = styled.div`
  position: fixed;
  width: 295px;
  background-color: white;
  border: #270fd601 solid 1px;
  z-index: 25;

  #title {
    padding: 8px;
    background-color: #f1f1f1;
    color: #5F5F5F;
    font-weight: bold;
  }

  #content {
    padding: 8px;
    font-family: Poppins;
    font-weight: 500;
    color: #5f5f5f;
  }

  #buttons {
    display: flex;
    justify-content: space-between;

    margin-top: 10px;
  }
`;

export const Button = styled.button<ButtonProps>`
  padding: 6px 18px;
  border: none;
  background-color: #270fd6;
  border-radius: 20px;
  color: white;
  font-size: 14px;

  ${({ refuse }) => refuse && css`
    background-color: #F14d4d;
  `}

  &:hover {
    filter: brightness(120%);
  }
`;
