export const searchOptions = {
  cdEmpresaAtendente: [
    {
      value: 1,
      label: 'Labor Import',
    },
    {
      value: 2,
      label: 'Medicorp',
    },
  ],
  cdNivelCriticidade: [
    {
      value: 'B',
      label: 'Baixo',
    },
    {
      value: 'M',
      label: 'Médio',
    },
    {
      value: 'A',
      label: 'Alto',
    },
  ],
  cdStatusAtendimento: [
    {
      value: 'P',
      label: 'Pendente',
    },
    {
      value: 'A',
      label: 'Em atendimento',
    },
    {
      value: 'F',
      label: 'Finalizado',
    },
    {
      value: 'C',
      label: 'Cancelado',
    },
  ],

};
