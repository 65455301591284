import { Paperclip, PaperPlaneRight } from 'phosphor-react';
import { toast } from 'react-toastify';
import { Content } from './styles';
import { theme } from '../../styles/theme';

interface IValoresProps {
  mensagem: string;
  setMensagemLocal: (value: string) => void;
  envioMensagem: () => void;
  canSend: boolean
  setFile: (file: any) => void;
  file: string;
}

interface Event<T = EventTarget> {
  target: T;
  // ...
}

function ChatInput({
  mensagem,
  setMensagemLocal,
  envioMensagem, canSend,
  setFile,
  file,
}: IValoresProps) {
  function handleChange(event: Event<HTMLInputElement>) {
    const imageFile = event.target.files;
    if (imageFile?.length) {
      const mbSize = imageFile[0].size / 1024 / 1024;
      if (mbSize <= 10) {
        setFile(imageFile[0]);
      } else {
        toast.error('O tamanho do arquivo não pode ultrapassar 10MB');
      }
    }
  }

  return (
    <Content>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          envioMensagem();
        }}
        id="box"
      >
        <div id="textBox">
          <div id="divInput">
            <label htmlFor="image" className="button">
              <Paperclip
                size={24}
                weight="fill"
                color={file ? theme.colors.blueMain : theme.colors.gray500}
              />
              <input type="file" id="image" accept="/*" onChange={handleChange} />
            </label>
          </div>
          <input
            type="text"
            value={mensagem}
            disabled={!canSend}
            autoComplete="off"
            onChange={(e) => setMensagemLocal(e.target.value)}
            id="escreve"
            placeholder={canSend ? 'Escreva sua mensagem aqui...' : 'Apenas atendentes desta chamada podem enviar mensagem!'}
          />
          {
            canSend && (
            <div id="button">
              <button
                type="submit"
                title="Enviar"
              >
                <PaperPlaneRight color={theme.colors.greenMain} weight="fill" size={24} />

              </button>
            </div>
            )
          }
        </div>
      </form>
    </Content>
  );
}

export default ChatInput;
