import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import Peer, { SignalData } from 'simple-peer';
import { useSocket } from './useSocket';

interface IMediaContextProps{
  localStream: MediaStream | null;
  setLocalStream:React.Dispatch<React.SetStateAction<MediaStream | null>>;
  getStream: ()=> Promise<MediaStream>;
  localStreamRef: React.MutableRefObject<any>;
  stopStream: () => Promise<void>;
  generateLocalPeer: (CdAtendimento: any) => Promise<void>;
  generateRemotePeers?: (CdAtendimento: any) => Promise<void>;
  peers: Peer.Instance[];
  handleVideoAction: (audioStream: boolean, videoStream: boolean) => void;
  indicatorsRef: React.MutableRefObject<Indicator[]>;
}
interface AddPeerParameters {
  incomingSignal: SignalData;
  cdUsuario?: number;
  cdCliente?: number;
  cdAtendimento: number;
  stream: MediaStream;
}

interface ReceivedSignal {
  cdUsuario?: number;
  cdCliente?: number;
}

interface CreatePeer {
  incomingSignal: ReceivedSignal;
  stream: MediaStream;
  cdAtendimento: number;
}

interface Indicator {
  cdAtendimento: number;
  cdCliente?: number;
  cdUsuario?: number;
  muted: boolean;
  hidden: boolean;
}

interface SocketSignal{
  signal: SignalData;
  from: {
    cdCliente?: number;
    cdUsuario?: number;
  }
  to: {
    cdCliente?: number;
    cdUsuario?: number;
  }
  cdAtendimento: number;
}

const MediaContext = createContext<IMediaContextProps>({} as any);

const iceServers = [{
  urls: ['stun:sp-turn1.xirsys.com'],
}, {
  username: 'g2ZDEPqhhAJUFOqUgLKf36oX81_cOzn_DOWIh0RFVsuGgaSKQByuGwob-qJOnxHhAAAAAGMObWBMQUJPUklNUE9SVA==',
  credential: '00596666-289f-11ed-82b9-0242ac120004',
  urls: [
    'turn:sp-turn1.xirsys.com:80?transport=udp',
    'turn:sp-turn1.xirsys.com:3478?transport=udp',
    'turn:sp-turn1.xirsys.com:80?transport=tcp',
    'turn:sp-turn1.xirsys.com:3478?transport=tcp',
    'turns:sp-turn1.xirsys.com:443?transport=tcp',
    'turns:sp-turn1.xirsys.com:5349?transport=tcp',
  ],
}];

export const MediaProvider = ({ children } : any) => {
  const { pathname } = useLocation();
  const { receivedSignals,
    socket,
    setReceivedSignals,
    sendLocalSignal,
    setInvitationResponse,
  } = useSocket();

  const [peers, setPeers] = useState<Peer.Instance[]>([]);
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [cdAtendimentoState, setCdAtendimentoState] = useState<number>();
  const [indicators, setIndicators] = useState<Indicator[]>([]);
  const userId = Number(sessionStorage.getItem('id'));

  const peersRef = useRef<any>([]);
  const localStreamRef = useRef<any>(null);
  const indicatorsRef = useRef<Indicator[]>([]);

  const getStream = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: {
      width: { max: 1280 },
      height: { max: 720 },
      frameRate: { max: 30 },
    },
    audio: true });

    setLocalStream(stream);
    return stream;
  };
  const stopStream = async () => {
    if (localStream) localStream?.getTracks().forEach(track => track.stop());
    peersRef.current = [];
    setReceivedSignals([]);
    setPeers((oldPeers) => {
      oldPeers.forEach(peer => peer.destroy());
      return [];
    });
    localStreamRef.current = null;
    setLocalStream(null);
    setInvitationResponse(null);
  };

  function createPeer({ incomingSignal, stream, cdAtendimento }: any) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
      config: { iceServers },
      channelName: 'chat',
      channelConfig: {
        negotiated: true,
        id: userId * userId,
      },
    });

    peer.on('signal', signal => {
      socket!.emit('initiator signal', {
        signal,
        from: { cdUsuario: userId },
        to: { ...(incomingSignal.cdUsuario
          ? { cdUsuario: incomingSignal.cdUsuario }
          : { cdCliente: incomingSignal.cdCliente }) },
        cdAtendimento,
      });
    });

    peersRef.current.push({
      peerID: incomingSignal.cdUsuario || incomingSignal.cdCliente,
      peer,
    });

    return peer;
  }

  function addPeer({
    incomingSignal,
    cdUsuario,
    cdCliente,
    cdAtendimento,
    stream }: AddPeerParameters) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
      config: { iceServers },
      channelName: 'chat',
      channelConfig: {
        negotiated: true,
        id: cdUsuario || cdCliente,
      },
    });

    peer.on('signal', signal => {
      socket!.emit('receiver signal', {
        signal,
        from: { cdUsuario: userId },
        to: { ...(cdUsuario ? { cdUsuario } : { cdCliente }) },
        cdAtendimento });
    });

    peer.signal(incomingSignal);

    return peer;
  }

  const generateLocalPeer = async (cdAtendimento: any) => {
    setCdAtendimentoState(cdAtendimento);
    const stream = localStream || await getStream();
    localStreamRef.current.srcObject = stream;
    socket!.emit('current signal', {
      peer: '',
      cdUsuario: userId,
      cdAtendimento,
    });

    const peersArray: Peer.Instance[] = [];
    const indicatorsArray: Indicator[] = [];

    receivedSignals?.forEach(signal => {
      indicatorsArray.push({
        ...(signal.cdUsuario ? { cdUsuario: signal.cdUsuario } : { cdCliente: signal.cdCliente }),
        cdAtendimento,
        muted: false,
        hidden: false,
      });
      const peer = createPeer({
        incomingSignal: signal,
        stream,
        cdAtendimento,
      });
      if (signal.cdUsuario) peersArray.push(peer);
    });
    setPeers(peersArray);
    indicatorsRef.current = indicatorsArray;
    setIndicators(indicatorsArray);

    socket!.off('initiator signal');
    socket!.on('initiator signal', (payload: SocketSignal) => {
      if (payload.to.cdUsuario !== userId) return;
      const peer = addPeer({
        incomingSignal: payload.signal,
        ...(payload.from.cdUsuario
          ? { cdUsuario: payload.from.cdUsuario }
          : { cdCliente: payload.from.cdCliente }),
        cdAtendimento,
        stream,
      });

      setPeers((oldPeers: any) => [...oldPeers, peer]);
      indicatorsRef.current = [...indicatorsRef.current, {
        ...payload.from.cdUsuario
          ? { cdUsuario: payload.from.cdUsuario }
          : { cdCliente: payload.from.cdCliente },
        cdAtendimento,
        muted: false,
        hidden: false,
      }];
      setIndicators(indicatorsRef.current);
    });

    socket!.off('receiver signal');
    socket!.on('receiver signal', (payload: SocketSignal) => {
      if (payload?.to?.cdUsuario !== userId) return;
      const item = peersRef.current.find((p: any) => (
        p.peerID === payload.from?.cdUsuario || p.peerID === payload.from?.cdCliente));
      item?.peer?.signal(payload.signal);
    });

    socket!.off('get signal');
    socket!.on('get signal', () => {
      if (stream) {
        sendLocalSignal({
          cdUsuario: userId,
          cdAtendimento,
        });
      }
    });

    socket!.off('video action notification');
    socket!.on('video action notification', (action: Indicator) => {
      console.log('video action notififcation', action);
      const newIndicators = [...indicatorsRef.current];
      let currentIndicator = newIndicators.find(item =>
        (action.cdUsuario ? action.cdUsuario : action.cdCliente)
        === (action.cdUsuario ? item.cdUsuario : item.cdCliente));
      if (!currentIndicator) {
        return;
      }
      currentIndicator.muted = action.muted;
      currentIndicator.hidden = action.hidden;
      indicatorsRef.current = newIndicators;
      setIndicators(indicatorsRef.current);
    });
  };

  function handleVideoAction(audioStream: boolean, videoStream: boolean) {
    socket.emit('video action', { cdAtendimento: cdAtendimentoState, cdUsuario: userId, muted: !audioStream, hidden: !videoStream } as Indicator);
  }

  useEffect(() => {
    if (!pathname.includes('requests-form') && localStream) { stopStream(); }
  }, [pathname]);

  return (
    <MediaContext.Provider
      value={{
        localStream,
        setLocalStream,
        getStream,
        localStreamRef,
        stopStream,
        generateLocalPeer,
        // generateRemotePeers,
        peers,
        handleVideoAction,
        indicatorsRef,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

export const useMedia = () => {
  const context = useContext(MediaContext);
  return context;
};
