import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { PdfAtendimentos } from '../components/PdfAtendimentos';
import useAxios from '../hooks/useAxios';

export const generatePdfDocument = async (filters) => {
  let data;

  const callNurseApi = useAxios();

  try {
    const response = await callNurseApi.post('/atendimento/pageSelect', {
      pageNumber: 1,
      pageCount: 500,
      ...filters,
    });
    data = response.data.atendimentos;
    const fileName = (new Date()).toLocaleString('pt-br');
    const blob = await pdf((
      <PdfAtendimentos
        filters={filters}
        atendimentos={data}
      />
    )).toBlob();
    saveAs(blob, fileName);
  } catch (e) {
    console.log(e);
  }
};
