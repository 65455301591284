import React, { forwardRef, useEffect } from 'react';
import { File } from 'phosphor-react';
import { Content, MessageContainer } from './style';
import User from '../../assets/user.png';
import { IMensagem } from '../../pages/RequestForm';
import { dateFullToString } from '../../utils/dateConversion';
import { variables } from '../../configuration/Constants';

interface ChatMessageProps {
  chat: IMensagem[];
}

function transformMessage(mensagem: IMensagem) {
  return (
    <MessageContainer owner key={mensagem.cdMensagem}>

      <div id="title">
        <img src={User} id="user" alt="" />
        <div id="box">
          <h3>{mensagem.nomeRemetente}</h3>

          {mensagem.extensaoArquivo !== '.pdf' && mensagem.nomeArquivo ?
            (
              <div id="image-container">
                <img id="image-message" src={`${variables.IMAGES_URL}${mensagem.nomeArquivo}${mensagem.extensaoArquivo}`} alt={mensagem.conteudo} />
              </div>
            ) : (mensagem.extensaoArquivo === '.pdf' && (
              <div id="pdf-container">
                <a href={`${variables.IMAGES_URL}${mensagem.nomeArquivo}${mensagem.extensaoArquivo}`} target="_blank" rel="noopener noreferrer">{mensagem.nomeArquivo}{mensagem.extensaoArquivo}<File size={20} /></a>
              </div>
            )

            )}

          <div id="message">
            <p>{mensagem.conteudo}</p>
          </div>
        </div>
        <div id="date">
          <p>{dateFullToString(new Date(mensagem.dataEnvio))}</p>
        </div>
      </div>

    </MessageContainer>
  );
}

export const ChatMessages = forwardRef<HTMLDivElement, ChatMessageProps>(({ chat }, ref) => (
  <Content ref={ref}>

    {chat && chat.map(transformMessage)}

  </Content>
));
