/* eslint-disable arrow-body-style */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { useMatch, useParams } from 'react-router-dom';

import { Content } from './styles';
import { Title } from '../Title';
import { Input } from '../Input';
import { messages } from '../../utils/statusMessage';
import { Select } from '../Select';
import { Button } from '../Button';
import useAxios from '../../hooks/useAxios';

const FormData = require('form-data');

export interface FaqData {
  ordem?: string;
  titulo?: string;
  conteudo?: string;
  cdFaq?: number;
  ativo?: boolean;
  link: string;
}

export function EditFaq() {
  const formRef = useRef<FormHandles>(null);
  const [faq, setFaq] = useState<FaqData>();

  let { id } = useParams();

  const callNurseApi = useAxios();

  const userId = sessionStorage.getItem('id');

  const buscarFaq = async () => {
    try {
      const { data } = await callNurseApi.get(`/faq/${id}`);
      if (data.status) {
        setFaq(data.faq);
      }
    } catch (error) {
      toast.error('Erro ao buscar dados');
    }
  };

  const handleSubmit = async () => {
    try {
      const corpo = {
        cdFaq: +id!,
        cdUsuarioAlteracao: +userId!,
        titulo: faq?.titulo,
        conteudo: faq?.conteudo,
        // ordem: faq?.ordem,
        ativo: faq?.ativo,
        link: faq?.link,
      };

      const response = await callNurseApi.put('/faq/', corpo);
      if (response?.data?.status) {
        toast.success('FAQ alterado com sucesso!');
        buscarFaq();
      } else {
        toast.error('Erro ao alterar FAQ');
      }
    } catch (error) {
      toast.error('Erro ao buscar dados');
    }
  };

  useEffect(() => {
    buscarFaq();
  }, []);

  return (
    <Content>
      <Title title="Gerenciamento de FAQ" backButton />
      <h2>Editar</h2>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <div id="faq-info">
          <Select
            name="Ativo"
            label="Status"
            id="Ativo"
            options={messages.ativo}
            value={messages.ativo.find(item => item.value === faq?.ativo)}
            onChange={({ value }) => setFaq(oldFaq => ({ ...oldFaq, ativo: value }))}
            placeholder=""
          />

          <Input
            name="titulo"
            label="Título"
            id="titulo"
            value={faq?.titulo}
            onChange={({ target: { value } }) => setFaq((oldFaq) => ({ ...oldFaq, titulo: value }))}
          />
          <Input
            name="link"
            label="Link"
            placeholder="http://"
            type="url"
            value={faq?.link}
            onChange={({ target: { value } }) => setFaq((oldFaq) => ({ ...oldFaq, link: value }))}
          />
        </div>
        <p>Resposta</p>
        <textarea
          id="messageItem"
          spellCheck="false"
          value={faq?.conteudo}
          onChange={({ target: { value } }) => setFaq((oldFaq) => ({ ...oldFaq, conteudo: value }))}
        />
        <div id="button">
          <Button type="submit">Salvar</Button>
        </div>
      </Form>
    </Content>
  );
}
