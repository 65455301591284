import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../Button';
import { RadioButton } from '../Checkbox';
import { Container } from './styles';
import BackArrowIcon from '../../assets/arrow.svg';

interface ModalProps {
  setEndCallModal: (endCallModal: boolean) => void,
  endCallModal: boolean;
  onEndCall: (inativo: boolean) => void;
  onLeaveCall: any;
  type: string;
}

export default function EndCallModal({
  onEndCall,
  endCallModal,
  setEndCallModal,
  onLeaveCall,
  type,
}: ModalProps) {
  const [motivo, setMotivo] = useState('nao');

  function handleConfirmar() {
    onEndCall(motivo === 'inativo');
    setEndCallModal(false);
  }

  function handleLeaveCall() {
    onLeaveCall();
    setEndCallModal(false);
  }

  function handleModal() {
    setEndCallModal(false);
  }

  const handleMotivo = (e: any) => {
    setMotivo(e.target.value);
    console.log(e.target.value);
  };

  if (type === 'end call') {
    return (
      <Container id="modal" showModal={endCallModal}>
        <div id="FinalizarChamada">
          <div id="text">

            <h1>Deseja Finalizar o atendimento?</h1>
          </div>
          <div id="labelCheck">
            <h3>Algum desses motivos se encaixa com a finalização do atendimento?</h3>
          </div>
          <div id="check">
            <div id="inativo">
              <RadioButton
                value="inativo"
                name="inativo"
                label="Inatividade do cliente"
                checked={motivo === 'inativo'}
                onChange={handleMotivo}
              />
            </div>
            <div id="motivo">
              <RadioButton
                value="nao"
                name="motivo"
                label="Não"
                checked={motivo === 'nao'}
                onChange={handleMotivo}
              />
            </div>
          </div>
          <div id="buttons">
            <Button
              id="confirmar"
              onClick={() => {
                handleConfirmar();
              }}
            >
              Confirmar
            </Button>
            <Button onClick={() => handleModal()}>
              Cancelar
            </Button>
          </div>

        </div>
      </Container>
    );
  }

  return (
    <Container id="modal" showModal={endCallModal}>
      <div id="FinalizarChamada">
        <div id="text">

          <h1>Deseja sair do atendimento?</h1>
        </div>

        <div id="buttons">
          <Button
            id="confirmar"
            onClick={() => handleLeaveCall()}
          >
            Confirmar
          </Button>
          <Button onClick={() => handleModal()}>
            Cancelar
          </Button>
        </div>

      </div>
    </Container>
  );
}
