import { useEffect, useState } from 'react';
import { ClockCounterClockwise, List, UserPlus } from 'phosphor-react';

import useWindowDimensions from '../../../hooks/useWindowsDimensions';
import { useSocket } from '../../../hooks/useSocket';
import { useAuth } from '../../../hooks/useAuth';
import { useMenu } from '../../../hooks/useMenu';

import { Data } from '..';
import { ChatInfo } from '../../../components/ChatInfo';
import { AddUser } from '../../../components/AddUser';

import Door from '../../../assets/Vector.png';
import Cam from '../../../assets/Vector.svg';
import info from '../../../assets/info.svg';
import { theme } from '../../../styles/theme';
import { Container, ContainerIcons } from './styles';

interface ChatHeaderProps {
  requestData: Data;
  onVideoBoxChange: () => void;
  onEndCall: (action: string) => void;
  setVisibleHistoricMobile: (visibleHistoricMobile: boolean) => void;
  visibleHistoricMobile: boolean;
}

export function ChatHeader({
  requestData,
  onEndCall,
  onVideoBoxChange,
  setVisibleHistoricMobile,
  visibleHistoricMobile,
}: ChatHeaderProps) {
  const { videoOffer, setVideoOffer, invitationResponse } = useSocket();
  const { cdUsuario } = useAuth();
  const { handleShowMenu } = useMenu();
  const { isScreenMobile } = useWindowDimensions();

  const [receivedCall, setReceivedCall] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);

  const eligibleCall =
    requestData?.cdStatusAtendimento === 'A' && ((requestData?.cdAtendenteAuxiliar === cdUsuario) || (requestData?.cdAtendente === cdUsuario));
  // for a call to be eligble

  function handleAddUserModal() {
    if (showInfo) setShowInfo(false);
    setAddUserModal(oldAddUserModal => !oldAddUserModal);
  }

  function handleInfoModal() {
    if (addUserModal) setAddUserModal(false);
    setShowInfo((value) => !value);
  }

  function handleHistoricModal() {
    setVisibleHistoricMobile(!visibleHistoricMobile);
  }

  useEffect(() => {
    if (videoOffer) {
      if (videoOffer.cdUsuario !== Number(cdUsuario!)) {
        setReceivedCall(true);
        setVideoOffer(null);
      }
    }
  }, [videoOffer]);

  return (
    <Container>
      <div id="info">
        {
          isScreenMobile ? (
            <>
              <button type="button" onClick={handleShowMenu}>
                <List size={24} color={theme.colors.whiteMain} weight="bold" />
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={() => {
                handleInfoModal();
              }}
            >
              <img src={info} alt="Info" />
            </button>
          )
        }
        {showInfo && (
          <ChatInfo requestData={requestData} />
        )}
        <h3 id="nameChat">{requestData?.nomeCliente}</h3>
      </div>
      <ContainerIcons>
        {
          isScreenMobile && (
            <button
              title="Histórico"
              type="button"
              onClick={() => {
                handleHistoricModal();
              }}
            >
              <ClockCounterClockwise
                color="#fff"
                size={24}
                weight="bold"
              />
            </button>
          )
        }
        {
        eligibleCall && (
          <>
            {
              isScreenMobile && (
                <div style={isScreenMobile && { margin: 0 }}>
                  <button
                    title="Informações de atendimento"
                    type="button"
                    onClick={() => {
                      handleInfoModal();
                    }}
                  >
                    <img src={info} alt="Info" />
                  </button>
                </div>
              )
            }
            {(requestData.cdAtendente === cdUsuario && !invitationResponse?.accepted) && (
              <div id="add-user">
                <button
                  title="Adicionar atendente"
                  type="button"
                  onClick={handleAddUserModal}
                >
                  <UserPlus weight="fill" color="#fff" size={28} />
                </button>

                {addUserModal && (
                  <AddUser
                    cdAtendimento={requestData.cdAtendimento}
                    cdEmpresa={requestData.cdGrupo}
                  />
                )}
              </div>
            )}
            <button
              title="Abrir webcam"
              type="button"
              onClick={onVideoBoxChange}
            >
              <img src={Cam} id="cam" alt="Iniciar Chamada de Vídeo" />
              {receivedCall && <div id="callDot" />}
            </button>
            {requestData.cdAtendente === cdUsuario && (
              <button
                type="button"
                title="Finalizar chamada"
                onClick={() => onEndCall('end call')}
                id="door"
              >
                <img
                  src={Door}
                  alt="Sair"
                />
              </button>
            )}
            {requestData.cdAtendenteAuxiliar === cdUsuario && (
              <button
                type="button"
                title="Sair da chamada"
                onClick={() => onEndCall('leave call')}
                id="door"
              >
                <img
                  src={Door}
                  alt="Sair"
                />
              </button>
            )}
          </>
        )
              }
      </ContainerIcons>
    </Container>
  );
}
