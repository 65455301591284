import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  margin: 2rem 0rem;
  button {
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${theme.colors.blueMain};
    border: none;
    color: ${theme.colors.background};
    margin-right: 1rem;
    border-radius: 50%;

    &:disabled {
      background: ${theme.colors.gray500};
      cursor: not-allowed;
    }
  }
  input {
    border: 2px solid ${theme.colors.gray400};
    width: 4rem;
    height: 3rem;
    border-radius: 1rem;
    text-align: center;
  }
  select {
    border: 2px solid ${theme.colors.gray400};
    width: 15rem;
    height: 3rem;
    border-radius: 1rem;
    text-align: center;
  }
  strong {
    font-weight: bold;
  }

`;
