/* eslint-disable no-undef */
/* eslint-disable arrow-body-style */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Content } from './styles';
import { UserInfo } from '../../components/UserInfo';
import { Title } from '../../components/Title';
import { encryptor, keySessionStorage } from '../../configuration/Constants';
import { IBusiness, IPermission, UserPermissions } from '../../components/UserPermissions';
import { SelectBusinessDTO } from '../../DTO/Request/SelectBusiness';
import { SelectPermissionsDTO } from '../../DTO/Request/SelectPermissions';
import { useAuth } from '../../hooks/useAuth';
import { TabLink } from '../UserForm/styles';
import useAxios from '../../hooks/useAxios';

export interface Permission {
  nome: string;
  Id: string;
  email: string;
  senha: string;
  usuarioInclusao: number;
}

export function CreateUserForm() {
  const formRef = useRef<FormHandles>(null);
  const { cdUsuario } = useAuth();
  const { pathname } = useLocation();
  const [activePage, setActivePage] = useState(1);

  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);

  const [permissionsList, setPermissionsList] = useState<SelectPermissionsDTO[]>();
  const [businessesList, setBusinessesList] = useState<SelectBusinessDTO[]>();

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const callNurseApi = useAxios();

  const handleSubmit = async () => {
    try {
      if (!businesses.length) {
        throw new Error('Associe ao menos uma empresa ao usuário');
      }
      const formattedResponse = {
        nome,
        email,
        senha: password,
        permissoes: permissions,
        empresas: businesses,
        usuarioInclusao: cdUsuario,
      };
      const response = await callNurseApi.post('/usuario/', formattedResponse);
      if (response.data.status) {
        toast.success('Usuário criado com sucesso!');
        if (formRef.current) {
          formRef.current.reset();
        }
        navigate('/users');
      }
    } catch (e) {
      toast.error(e.message || 'Error ao criar usuário!');
    }
  };

  const tabs: {[key: number]: any} = {
    0: () => (
      <UserPermissions
        permissions={permissions}
        setPermissions={setPermissions}
        businesses={businesses}
        setBusinesses={setBusinesses}
        businessesList={businessesList}
        permissionsList={permissionsList}
      />
    ),
    1: () => (
      <UserInfo
        nome={nome}
        setName={setNome}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
      />
    ),
  };

  const getBusiness = async () => {
    try {
      const { data } = await callNurseApi.post('/empresa/select', {
        cdTipoEmpresa: 1,
        pageNumber: 1,
        pageCount: 999,
      });
      if (data.status) {
        setBusinessesList(data.empresas);
      }
    } catch (error) {
      toast.error('Erro ao buscar dados');
    }
  };

  const getPermissions = async () => {
    try {
      const { data } = await callNurseApi.get('/permissao/all');
      if (data.status) {
        setPermissionsList(data.permissoes);
      }
    } catch (error) {
      toast.error('Erro ao buscar dados');
    }
  };

  useEffect(() => {
    Promise.all([getBusiness(), getPermissions()]);
  }, []);

  return (
    <Content>
      <Title title="Usuários" backButton />
      <nav>
        <TabLink
          to={pathname}
          onClick={() => setActivePage(1)}
          active={activePage === 1}
        >
          Informações
        </TabLink>
        <TabLink
          onClick={() => setActivePage(0)}
          to={pathname}
          active={activePage === 0}
        >
          Permissões
        </TabLink>
      </nav>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
      >
        {
        tabs[activePage]()
       }
      </Form>
    </Content>
  );
}
