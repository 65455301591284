import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useWindowDimensions from './useWindowsDimensions';

interface MenuProviderProps {
  children: ReactNode;
}

interface MenuData {
  showMenu: boolean;
  handleShowMenu: () => void;
}

const MenuContext = createContext<MenuData>({} as MenuData);

function MenuProvider({ children }: MenuProviderProps) {
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();
  const isScreenMobile = width < 720;
  const [showMenu, setShowMenu] = useState(!isScreenMobile);

  function handleShowMenu() {
    setShowMenu(state => !state);
  }

  useEffect(() => {
    if (isScreenMobile) {
      setShowMenu(false);
    }
  }, [pathname]);

  return (
    <MenuContext.Provider value={{
      handleShowMenu,
      showMenu,
    }}
    >
      {children}
    </MenuContext.Provider>
  );
}

function useMenu() {
  return useContext(MenuContext);
}

export {
  useMenu,
  MenuProvider,

};
