import styled, { css } from 'styled-components';
import { theme } from '../../styles/theme';

interface ContainerProps{
    showModal: boolean;
  }

export const Container = styled.div<ContainerProps>`
    position: absolute;
    display: flex;
    align-items: center;

    position: fixed;
    overflow: hidden;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    height: 100vh;

    z-index: 11;

    background-color: rgba(0, 0, 0, 0.5);


${
  props =>
    !props.showModal &&
    css`
    display: none;
    `
}

#FinalizarChamada {
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 2.0rem;
    background-color: ${theme.colors.background};
    border: 1.5px solid ${theme.colors.borderColor};

    height: auto;
    width: auto;
    position: relative;
    align-items: center;

    padding: 3rem 5rem;

    #text {
    color: #21457f;
    display: flex;
    flex-direction: row;
    width: max-content;
    justify-content: center;

    #backButton {
      display: flex;
      background-color: transparent;
      border: 0;
      width: 35px;
      justify-content: flex-start;

      img {
        display: flex;
        width: 40%;
      }
    }
        h1 {
        font-family: Poppins;
        font-size: 22px;
        font-weight: 700;
        line-height: 33px;
      }
    }
  }

  #labelCheck {
    display: flex;
    width: max-content;
    justify-content: center;

      h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      margin-top: 2rem;
      color: #5f5f5f;
      }
    }

  #check {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }


  #buttons{
    display: flex;
    justify-content: center;

    gap: 5rem;
    margin-top: 6rem;
    button {
      width: fit-content;
    }
  }

  @media(max-width: 720px) {
    #FinalizarChamada {
      overflow: hidden;    

      width: 56vh;
    
      border-radius: 10px;
      margin: 10px;

      #text {
        width: 25vh;
        
      }

      #labelCheck {
        h3 {
          width: 40vh;
        }
      }
    }
  }
`;
