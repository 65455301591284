import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  .new {
      transform: translateX(-100%);
      -webkit-animation: slide 2s forwards ;
      -webkit-animation-delay: 10ms;
      animation: slide 1s forwards ;
      animation-delay: 10ms;
      opacity: 0;
      transition: 0.5s
  }

  #buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 4px 0px 10px 0px;
    button {
      width: 18rem;
    }
  }

  #refreshButton {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2rem;

    button{
      background-color: ${theme.colors.greenMain};

      &:hover {
        background-color: ${theme.colors.greenLogo};
      }
    }


    @media(max-width: 720px){
      display: flex;
    }
  }

  @-webkit-keyframes slide {
    100% {
        opacity: 1 ;
        transform: translateX(0);
    }
  }

  @keyframes slide {
    100% {
        opacity: 1 ;
        transform: translateX(0);
    }
  }
  `;

export const Content = styled.div`
  display: grid;
  justify-content: left;
  grid-gap: 2.4rem;
  align-items: top;
  width: 100%;


  div{
    width: 100%;


    h1{
      color: ${theme.colors.blueMain};
      font-weight: bold;
      font-size: 32px;
      margin-bottom: 16px;
    }
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;
