/* eslint-disable no-undef */
import { useState, createContext, useContext } from 'react';
import { toast } from 'react-toastify';

import { keySessionStorage, encryptor } from '../configuration/Constants';
import { Business, Permission } from '../DTO/Response/SelectUser';
import useAxios from './useAxios';

interface AuthContextData {
  accessToken: string | null;
  setAccessToken: any;
  login: any;
  logout: () => void;
  setToken: (token:string) => void;
  cdUsuario: number | null
  permissions: Permission[];
  associatedBusinesses: Business[];
}

export type dataUserResponse = {
  status:boolean,
  message:string,
  cdUsuario:number
}

const AuthContext = createContext<AuthContextData>({} as any);

export function AuthProvider({ children } : any) {
  const callNurseApi = useAxios();

  const [cdUsuario, setCdUsuario] = useState(() =>
    (Number(sessionStorage.getItem(keySessionStorage.KEY_ID))));

  const [accessToken, setAccessToken] = useState(() =>
    (sessionStorage.getItem(keySessionStorage.KEY_TOKEN)));

  const [permissions, setPermissions] = useState(() =>
    (JSON.parse(encryptor.decrypt(sessionStorage.getItem(keySessionStorage.KEY_PERMISSIONS)))));

  const [associatedBusinesses, setAssociatedBusinesses] = useState(() =>
    (JSON.parse(encryptor.decrypt(sessionStorage.getItem(keySessionStorage.KEY_BUSINESSES)))));

  function setToken(token: string) {
    try {
      setAccessToken(token);
      // sessionStorage.setItem(keySessionStorage.KEY_TOKEN, token);
    } catch (e) {
      console.log(e);
    }
  }

  async function login(email: string, password: string) {
    try {
      const response = await callNurseApi.post('/usuario/login', { email, senha: password });
      const validation = response.data;
      if (validation.status) {
        sessionStorage.setItem('id', validation.cdUsuario);

        const { data: { usuario: { permissoes: permissionsList, empresas: businessesList } } } = await callNurseApi.get(`/usuario/${validation.cdUsuario}`);

        console.log({ permissionsList });
        if (businessesList.length < 1) throw new Error('Usuário não tem acesso a nenhuma empresa');

        sessionStorage.setItem(keySessionStorage.KEY_PERMISSIONS,
          encryptor.encrypt(JSON.stringify(permissionsList)));

        sessionStorage.setItem(keySessionStorage.KEY_BUSINESSES,
          encryptor.encrypt(JSON.stringify(businessesList)));

        setCdUsuario(validation.cdUsuario);
        setPermissions(permissionsList);
        setAssociatedBusinesses(businessesList);

        return true;
      }
      throw new Error('Email e/ou senha inválidos');
    } catch (error) {
      toast.error('Email e/ou senha inválidos');
      return false;
    }
  }

  function logout() {
    sessionStorage.removeItem(keySessionStorage.KEY_TOKEN);
    sessionStorage.removeItem(keySessionStorage.KEY_PERMISSIONS);
    sessionStorage.removeItem(keySessionStorage.KEY_ID);
    window.location.reload();
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        accessToken,
        setAccessToken,
        logout,
        cdUsuario,
        permissions,
        associatedBusinesses,
        setToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
