import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  background-color: #21457F;
  height: 55px;
  padding: 10 0px;
  width: 100%;
  border-right: 4px solid #ffffff;
  position: sticky;
  top: 0;
  padding: 1rem;

  @media(max-width: 720px){
    border: 0;
  }

  div#title {
    max-width: max-content;
  }

  #info {
    width: min-content;
    display: flex;
    align-items: center;
    text-overflow: clip;
    width: 65%;

    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button {
      background: none;
      border: none;
    }
    
    #nameChat {
      min-width: max-content;
    }

  }

`;

export const ContainerIcons = styled.div`
  display: flex;
  align-items: center;
  width: min-content !important;
  gap: 10px;
  position: relative;
  margin: 10px;

  button {
    border: 0;
    background: none;
  }

  #add-user {
    position: relative;
    width: fit-content;
  }
`;
