import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { GlobalStyle } from './styles/global';
import { Routing } from './routes';
import { AuthProvider } from './hooks/useAuth';
import ListaNotificacaoProvider from './hooks/useNotification';
import { SocketProvider } from './hooks/useSocket';
import { MediaProvider } from './hooks/useMedia';
import { ChatInvite } from './components/ChatInvite';
import { MenuProvider } from './hooks/useMenu';

function App() {
  useEffect(() => {
    const checkPermission = async () => {
      if (Notification.permission !== 'granted') {
        await Notification.requestPermission();
      }
    };
    checkPermission();
  }, []);
  return (
    <BrowserRouter>
      <AuthProvider>
        <SocketProvider>
          <MediaProvider>
            <ListaNotificacaoProvider>
              <MenuProvider>
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <ChatInvite />
                <Routing />
                <GlobalStyle />
              </MenuProvider>
            </ListaNotificacaoProvider>
          </MediaProvider>
        </SocketProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
