import ReactECharts from 'echarts-for-react'; // or var ReactECharts = require('echarts-for-react');
import { date } from 'yup/lib/locale';
import { Container } from './styles';
import { theme } from '../../styles/theme';
import { IDiaSemana } from '../DashboardData';
import useWindowDimensions from '../../hooks/useWindowsDimensions';

interface ChartProps {
  weeklyRequests: IDiaSemana[];
}

export function Chart({ weeklyRequests }: ChartProps) {
  const diaAtual = weeklyRequests.find(item => item.hoje)!;
  const { width } = useWindowDimensions();

  const getWeekDays = (callback: (item: IDiaSemana) => boolean) => (
    weeklyRequests
      .filter(item => !item.hoje && callback(item))
      .sort((a, b) =>
        (a.numeroDiaSemana > b.numeroDiaSemana ? 1 : 0))
  );

  const diasOrdenados = [...getWeekDays((item) =>
    item.numeroDiaSemana > diaAtual.numeroDiaSemana),
  ...getWeekDays((item) => item.numeroDiaSemana < diaAtual.numeroDiaSemana), diaAtual];

  console.log((new Date(diasOrdenados[6].dtDiaSemana)).toString());

  console.log(diasOrdenados);
  const options = {
    color: [theme.colors.blueMain],
    tooltip: {
      show: true,
    },
    dataset: {
      source: [
        ['type', ...diasOrdenados.map(({ nomeDiaSemana, dtDiaSemana }) => `${width > 720 ? nomeDiaSemana : nomeDiaSemana.slice(0, 3)} ${width > 720 ? (new Date(dtDiaSemana.slice(0)).toLocaleString('pt-BR', {
          timeZone: 'UTC',
          day: '2-digit',
          month: '2-digit',
        })) : ''}`)],
        ['Atendimentos', ...diasOrdenados.map(item => item.atendimentos)],
      ],
    },
    title: {
      text: width > 720 ? 'Total de atendimentos nos últimos 7 dias' : 'atendimentos nos últimos 7 dias',
    },
    legend: {
      bottom: '10',
      show: true,
      type: 'scroll',
    },
    xAxis: {
      type: 'category',
      axisTick: {
        show: false,
      },
    },
    yAxis: {},
    series: [{
      type: 'bar',
      seriesLayoutBy: 'row',
    }],
  };
  return (
    <Container>
      <ReactECharts
        option={options}
        style={{ height: '100%', width: '100%' }}
        className="charts"
      />
    </Container>
  );
}
