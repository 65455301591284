import { Briefcase, ChartBar, LineSegments, User } from 'phosphor-react';

import { Link } from 'react-router-dom';
import { Button } from '../Button';

import CallNurseHeart from '../../assets/call-nurse-heart.svg';
import Nurse from '../../assets/nurse.svg';
import { Container, Separator, Status } from './styles';
import { Atendimento } from '../../pages/Requests';

interface CallCardProps {
  callData: Atendimento;
}

const buttonLabelStatus : {[key: string] : string} = {
  P: 'Atender',
  A: 'Continuar',
  F: 'Ver histórico',
  C: 'Ver histórico',
};

const nomeStatus = {
  F: 'Finalizado',
  P: 'Pendentente',
  C: 'Cancelado',
  A: 'Em atendimento',

};

export function CallCard({ callData }: CallCardProps) {
  const date = new Date(callData.dataAbertura).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: 'short',
  });
  const time = new Date(callData.dataAbertura).toLocaleTimeString('pt-br', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <Container>
      <time>
        {time}<br />
        {date}
      </time>
      <h1>#{callData.cdAtendimento}</h1>
      <div>
        <Briefcase weight="fill" />
        <p>{callData.nomeGrupo}</p>
      </div>
      <div>
        <User weight="fill" />
        <p>{callData.nomeCliente}</p>
      </div>
      <div>
        <ChartBar weight="bold" />
        <Status color={callData.cdNivelCriticidade}>
          {callData.nomeNivelCriticidade}
        </Status>
      </div>
      <Separator />
      <div>
        <LineSegments weight="fill" />
        <Status color={callData.cdStatusAtendimento}>
          {nomeStatus[callData.cdStatusAtendimento]}
        </Status>
      </div>
      {
        callData.notaAtendimento && (
        <div>
          <img src={CallNurseHeart} alt="Nota do atendimento" />
          <p>{callData.notaAtendimento}</p>
        </div>
        )
      }
      {
        callData.nomeAtendente && (
        <div>
          <img src={Nurse} alt="Atendente" />
          <p>{callData.nomeAtendente}</p>
        </div>
        )
      }
      <Link to={`/requests-form/${callData.cdAtendimento}`}>
        <Button>{buttonLabelStatus[callData.cdStatusAtendimento]}</Button>
      </Link>
    </Container>
  );
}
