import { RedirectButton } from './styles';

interface IStatusButtonProps{
  status: string
}

const buttonLabelStatus : {[key: string] : string} = {
  P: 'Atender',
  A: 'Continuar',
  F: 'Ver histórico',
};

export const StatusButton = ({ status } : IStatusButtonProps) => (

  <RedirectButton
    status={status}
    type="button"
  >{buttonLabelStatus[status] || 'Ver'}
  </RedirectButton>
);
