import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useSocket, TargetedAction } from '../../hooks/useSocket';
import { Load } from '../Load';

import {
  Container,
  Content,
  ListItem,
} from './styles';

interface AddUserProps {
  cdAtendimento: number;
  cdEmpresa: number;
}

export function AddUser({ cdAtendimento, cdEmpresa }: AddUserProps) {
  const { socket, onlineUsers } = useSocket();
  const { cdUsuario } = useAuth();

  const eligibleOnlineUsers = onlineUsers
    .filter(user => {
      const position = user.permissoes.findIndex(permission => permission.idPermissao === 'atendimento');
      if (!position) return false;
      return user.permissoes[position].editar;
    })
    .filter(user => user.empresas.find(business => business.cdEmpresa === cdEmpresa));

  function handleSendInvite(to: number) {
    console.log('invite sent');
    socket!.emit('send invitation', { cdAtendimento, from: { cdUsuario }, to: { cdUsuario: to } } as TargetedAction);
  }

  useEffect(() => {
    socket!.emit('get online users');
  }, []);

  console.log({ onlineUsers });

  return (
    <Container>
      <Content>
        <div id="title">
          <span>Adicionar atendente</span>
        </div>
        <div id="content">
          {onlineUsers.length === 0 ? <Load size={20} /> :
            eligibleOnlineUsers.map((item, index) => (
              item.cdUsuario && (
              <button
                type="button"
                onClick={() => handleSendInvite(item.cdUsuario)}
              >
                <ListItem stripe={(index + 1) % 2 === 0}>
                  {item.nome}
                </ListItem>
              </button>
              )
            ))}
        </div>

      </Content>
    </Container>
  );
}
