/* eslint-disable no-undef */
/* eslint-disable max-len */
import { NavLink } from 'react-router-dom';

import { IPermissionKeys } from '../../DTO/Response/SelectUser';
import { useAuth } from '../../hooks/useAuth';

import { Container, Content, Logo } from './styles';

export function Sidebar(showMenu) {
  const { permissions } = useAuth();

  function checkPermission(idPermission: IPermissionKeys, type: 'edit' | 'view') {
    const foundPermission = permissions.find(item => item.idPermissao === idPermission);

    const hasPermission = type === 'view' ? foundPermission?.visualizar : foundPermission?.editar;

    return hasPermission || null;
  }

  const categories = [
    checkPermission('dashboard', 'view') && <NavLink key="dashboard" to="/">Dashboard</NavLink>,
    checkPermission('atendimento', 'view') && <NavLink key="requests" to="/requests">Atendimentos</NavLink>,
    checkPermission('empresa', 'view') && <NavLink key="clientes" to="/clients">Empresa</NavLink>,
    checkPermission('faq', 'view') && <NavLink key="faqs" to="/faqs">Gerenciamento de FAQ</NavLink>,
    checkPermission('usuario', 'view') && <NavLink key="users" to="/users">Usuários</NavLink>,
    // <NavLink key="employees" to="/employees">Colaboradores</NavLink>,
    // <NavLink key="products" to="/products">Produtos</NavLink>,
    // <NavLink key="notificacoes" to="/notifications">Notificações push</NavLink>,

  ];

  if (!showMenu) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Logo />
        {
            categories.map((item) => item)
          }
      </Content>
    </Container>
  );
}
