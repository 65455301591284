import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;

  #ButtonContainer {
    display: flex;
    flex-direction: row;
  }
`;

export const DataBox = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px 24px 0px;
  width: 100%;

  div {
    width: 100%;
    background-color: white;
    padding: 30px;
    margin-right: 10px;

    &:last-child{
      margin-right: 0;
    }
    .category{
      font-size: 14px;
      color: ${theme.colors.gray500}
    }
    .data{
      font-size:32px;
    }
  }
`;

export const TypeButton = styled.button<{selected? : boolean}>`

  background-color: ${({ selected }) => (selected ? theme.colors.blueMain : theme.colors.whiteMain)};
  border: ${theme.colors.borderColor};
  border-radius: 10px;
  width: 100%;
  color: ${({ selected }) => (selected ? theme.colors.whiteMain : theme.colors.gray600)};
  min-width: max-content;
  padding: 1rem;




`;
