import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 2ch;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  align-items: center;

  .subTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    color: #c7c7c7;
  }

  #title {
    color: #21457f;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    #backButton {
      justify-content: center;
      display: flex;
      background-color: transparent;
      border: 0;
      height: 22px;
      width: 35px;
      justify-content: flex-start;

      img {
        display: flex;
        width: 40%;
      }
    }

    h1 {
      font-family: Poppins;
      font-size: 22px;
      font-weight: 700;
      line-height: 33px;

      color: 21457F;
    }
  }

  section {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    div {
      display: flex;
      justify-content: center;
      align-items: baseline;
      gap: 1rem;

      img {
        width: 16px;
      }
    }

    .subTitle {
      min-width: fit-content;
    }

    h2 {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 2rem;
    }
  }

  button {
    width: 100%;
  }
`;
