import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  display:flex;
  height: 100vh;
`;

export const Content = styled.div`
  div{
    width: 100%;
  }

  nav{
    height: 2.2rem;
    a{
      font-size: 1.4rem;
      font-weight: 600;
      background-color: ${theme.colors.gray400};
      padding: 0.5rem 1.5rem 0.2rem 1.5rem;
      border-radius: 1rem 1rem 0rem 0rem;

      &.active{
        background: ${theme.colors.gray500};
        color: white;
      }
    }

  }

  form{
    background-color: white;
    padding: 3rem 2rem;
    }


  h2{
    background-color: ${theme.colors.gray500};
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 1.2rem;
  }

  h3{
    color: ${theme.colors.blueMain};
    font-size: 1.7rem;
    font-weight: bold;
  }

  .gray {
    color: ${theme.colors.gray500};
  }

  #faq-info{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;


    @media (max-width: 650px){
      grid-template-columns: 1fr;
    }
  }

    p {
      padding: 1rem;
    }
    textarea {
      width: 100%;
      height: 10rem;
      background: ${theme.colors.background};
      border-radius: 1.1rem;
      border: 0.2rem solid #e3e3e3;
      padding: 1.2rem;
      font-size: 16px;
      font-family: 'Poppins';

      &::placeholder{
      font-size: 18px;
      font-family: 'Poppins';
      }

      &:focus{
        border-color: ${theme.colors.blueMain}
      }
    }

  #button {
    width: 20%;
  }



`;
