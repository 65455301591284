import { Table } from 'phosphor-react';
import { Helmet } from 'react-helmet';
import { DashboardData } from '../../components/DashboardData';

export const Dashboard: React.FC = () => (
  <>
    <Helmet>
      <title>Bunzl | Dashboard</title>
    </Helmet>
    <DashboardData />
  </>
);
