/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable no-unneeded-ternary */
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { Content } from './styles';
import { SelectBusinessDTO } from '../../DTO/Request/SelectBusiness';
import { SelectPermissionsDTO } from '../../DTO/Request/SelectPermissions';
import { useAuth } from '../../hooks/useAuth';

interface UserPermissionsProps{
  permissions: IPermission[];
  setPermissions: (permission: IPermission[]) => void;
  businesses: IBusiness[];
  setBusinesses: (business: any) => void;
  businessesList: SelectBusinessDTO[];
  permissionsList: SelectPermissionsDTO[];
}

export interface IPermission {
  idPermissao: string;
  visualizar?: boolean;
  editar?: boolean;
}

export interface IBusiness {
  cdEmpresa: number;
}

export function UserPermissions({ permissions, setPermissions, businesses, setBusinesses, permissionsList, businessesList }: UserPermissionsProps) {
  const { associatedBusinesses } = useAuth();
  // const [permissions, setPermissions] = useState<Permission[]>([]);
  // const [businesses, setBusinesses] = useState<Business[]>([]);

  function handleCheckbox(value, id: string, permissionId) {
    console.log({ id });
    const updatedPermissions = [...permissions];
    let currentOption = updatedPermissions.find(item => item.idPermissao === permissionId);
    if (currentOption) {
      currentOption.idPermissao = permissionId;
      currentOption.editar = value && id === 'editar' ? true : false;
      currentOption.visualizar = value ? true : false;
    } else {
      const newOption = {
        idPermissao: permissionId,
        editar: value && id === 'editar' ? true : false,
        visualizar: value ? true : false,
      };
      updatedPermissions.push(newOption);
    }
    console.log(updatedPermissions);
    setPermissions(updatedPermissions);
  }

  function getPermissionValue(permission, idPermissao) {
    const findPermissions = [...permissions];
    const locatedPermission = findPermissions.find(item => item.idPermissao === idPermissao);
    if (locatedPermission) {
      return locatedPermission[permission];
    }

    return false;
  }

  function handleBusinessCheckbox(check: boolean, cdEmpresa: number) {
    if (check) {
      setBusinesses(oldBusinesses => [...oldBusinesses, { cdEmpresa }]);
    } else {
      const newBusinesses = (businesses.filter(item => item.cdEmpresa !== cdEmpresa));
      console.log(newBusinesses);
      setBusinesses(newBusinesses);
    }
  }

  return (
    <>
      <h2>Editar</h2>
      <Content>
        <h4>Módulos</h4>
        <table>
          <thead>
            <tr>
              <th>Módulo</th>
              <th>Visualização</th>
              <th>Edição</th>
            </tr>
          </thead>
          <tbody>
            {
            permissionsList?.map((permission) => (
              <tr key={permission.idPermissao}>
                <td>
                  <p>{permission.nomePermissao}</p>
                </td>
                <td>
                  <Checkbox
                    value="1"
                    name={`${permission.idPermissao}`}
                    id="visualizar"
                    onChange={(event) => handleCheckbox(event.target.checked, 'visualizar', permission.idPermissao)}
                    checked={getPermissionValue('visualizar', permission.idPermissao) || getPermissionValue('editar', permission.idPermissao)}
                  />
                </td>
                <td>
                  <Checkbox
                    value="2"
                    name={`${permission.idPermissao}`}
                    id="editar"
                    onChange={(event) => handleCheckbox(event.target.checked, 'editar', permission.idPermissao)}
                    checked={getPermissionValue('editar', permission.idPermissao)}
                  />
                </td>
              </tr>
            ))
          }
          </tbody>

        </table>

        <h4>Empresas</h4>
        <table>
          <thead>
            <tr>
              <th>Empresa</th>
              <th>Acesso</th>
            </tr>
          </thead>
          <tbody>
            {
            associatedBusinesses.map((business) => (
              <tr key={business.cdEmpresa}>
                <td>
                  <p>{business.razaoSocialEmpresa}</p>
                </td>
                <td>
                  <Checkbox
                    value="1"
                    name={`${business.razaoSocialEmpresa}`}
                    id="visualizar"
                    onChange={(event) => handleBusinessCheckbox(event.target.checked, business.cdEmpresa)}
                    checked={businesses?.some(item => item.cdEmpresa === business.cdEmpresa)}
                  />
                </td>
              </tr>
            ))
          }
          </tbody>

        </table>

        <div id="button">
          <Button type="submit">Salvar</Button>
        </div>

      </Content>
    </>
  );
}
